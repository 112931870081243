@charset "UTF-8";
.blob-mask-1 {
  -webkit-mask-image: url("/content/assets/images/blobs/blob-mask-1.svg");
          mask-image: url("/content/assets/images/blobs/blob-mask-1.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.blob-mask-1 img,
.blob-mask-1 video {
  vertical-align: middle;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  transform: translate(-50%, 0);
  left: 50%;
}

.square-blob-mask-1 {
  -webkit-mask-image: url("/content/assets/images/blobs/slider-mask-1.svg");
          mask-image: url("/content/assets/images/blobs/slider-mask-1.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}

.blob-mask-2 {
  -webkit-mask-image: url("/content/assets/images/blobs/blob-mask-2.svg");
          mask-image: url("/content/assets/images/blobs/blob-mask-2.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.blob-mask-2 img,
.blob-mask-2 video {
  vertical-align: middle;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  transform: translate(-50%, 0);
  left: 50%;
}

.square-blob-mask-2 {
  -webkit-mask-image: url("/content/assets/images/blobs/slider-mask-2.svg");
          mask-image: url("/content/assets/images/blobs/slider-mask-2.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}

.blob-mask-3 {
  -webkit-mask-image: url("/content/assets/images/blobs/blob-mask-3.svg");
          mask-image: url("/content/assets/images/blobs/blob-mask-3.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.blob-mask-3 img,
.blob-mask-3 video {
  vertical-align: middle;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  transform: translate(-50%, 0);
  left: 50%;
}

.square-blob-mask-3 {
  -webkit-mask-image: url("/content/assets/images/blobs/slider-mask-3.svg");
          mask-image: url("/content/assets/images/blobs/slider-mask-3.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}

.blob-mask-4 {
  -webkit-mask-image: url("/content/assets/images/blobs/blob-mask-4.svg");
          mask-image: url("/content/assets/images/blobs/blob-mask-4.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.blob-mask-4 img,
.blob-mask-4 video {
  vertical-align: middle;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  transform: translate(-50%, 0);
  left: 50%;
}

.square-blob-mask-4 {
  -webkit-mask-image: url("/content/assets/images/blobs/slider-mask-4.svg");
          mask-image: url("/content/assets/images/blobs/slider-mask-4.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}

.blob-mask-5 {
  -webkit-mask-image: url("/content/assets/images/blobs/blob-mask-5.svg");
          mask-image: url("/content/assets/images/blobs/blob-mask-5.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.blob-mask-5 img,
.blob-mask-5 video {
  vertical-align: middle;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  transform: translate(-50%, 0);
  left: 50%;
}

.square-blob-mask-5 {
  -webkit-mask-image: url("/content/assets/images/blobs/slider-mask-5.svg");
          mask-image: url("/content/assets/images/blobs/slider-mask-5.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}

.blob-mask-6 {
  -webkit-mask-image: url("/content/assets/images/blobs/blob-mask-6.svg");
          mask-image: url("/content/assets/images/blobs/blob-mask-6.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.blob-mask-6 img,
.blob-mask-6 video {
  vertical-align: middle;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  transform: translate(-50%, 0);
  left: 50%;
}

.square-blob-mask-6 {
  -webkit-mask-image: url("/content/assets/images/blobs/slider-mask-6.svg");
          mask-image: url("/content/assets/images/blobs/slider-mask-6.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}

.blob-mask-7 {
  -webkit-mask-image: url("/content/assets/images/blobs/blob-mask-7.svg");
          mask-image: url("/content/assets/images/blobs/blob-mask-7.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.blob-mask-7 img,
.blob-mask-7 video {
  vertical-align: middle;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  transform: translate(-50%, 0);
  left: 50%;
}

.square-blob-mask-7 {
  -webkit-mask-image: url("/content/assets/images/blobs/slider-mask-7.svg");
          mask-image: url("/content/assets/images/blobs/slider-mask-7.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}

.blob-mask-8 {
  -webkit-mask-image: url("/content/assets/images/blobs/blob-mask-8.svg");
          mask-image: url("/content/assets/images/blobs/blob-mask-8.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.blob-mask-8 img,
.blob-mask-8 video {
  vertical-align: middle;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  transform: translate(-50%, 0);
  left: 50%;
}

.square-blob-mask-8 {
  -webkit-mask-image: url("/content/assets/images/blobs/slider-mask-8.svg");
          mask-image: url("/content/assets/images/blobs/slider-mask-8.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}

.blob-mask-9 {
  -webkit-mask-image: url("/content/assets/images/blobs/blob-mask-9.svg");
          mask-image: url("/content/assets/images/blobs/blob-mask-9.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.blob-mask-9 img,
.blob-mask-9 video {
  vertical-align: middle;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  transform: translate(-50%, 0);
  left: 50%;
}

.square-blob-mask-9 {
  -webkit-mask-image: url("/content/assets/images/blobs/slider-mask-9.svg");
          mask-image: url("/content/assets/images/blobs/slider-mask-9.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}

.blob-mask-10 {
  -webkit-mask-image: url("/content/assets/images/blobs/blob-mask-10.svg");
          mask-image: url("/content/assets/images/blobs/blob-mask-10.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.blob-mask-10 img,
.blob-mask-10 video {
  vertical-align: middle;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  transform: translate(-50%, 0);
  left: 50%;
}

.square-blob-mask-10 {
  -webkit-mask-image: url("/content/assets/images/blobs/slider-mask-10.svg");
          mask-image: url("/content/assets/images/blobs/slider-mask-10.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}

.blob-mask-11 {
  -webkit-mask-image: url("/content/assets/images/blobs/blob-mask-11.svg");
          mask-image: url("/content/assets/images/blobs/blob-mask-11.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.blob-mask-11 img,
.blob-mask-11 video {
  vertical-align: middle;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  transform: translate(-50%, 0);
  left: 50%;
}

.square-blob-mask-11 {
  -webkit-mask-image: url("/content/assets/images/blobs/slider-mask-11.svg");
          mask-image: url("/content/assets/images/blobs/slider-mask-11.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}

.blob-mask-12 {
  -webkit-mask-image: url("/content/assets/images/blobs/blob-mask-12.svg");
          mask-image: url("/content/assets/images/blobs/blob-mask-12.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.blob-mask-12 img,
.blob-mask-12 video {
  vertical-align: middle;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  transform: translate(-50%, 0);
  left: 50%;
}

.square-blob-mask-12 {
  -webkit-mask-image: url("/content/assets/images/blobs/slider-mask-12.svg");
          mask-image: url("/content/assets/images/blobs/slider-mask-12.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}

@font-face {
  font-family: Poppins;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/content/assets/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins Bold";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("/content/assets/fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins Light";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("/content/assets/fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins Medium";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("/content/assets/fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins SemiBold";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("/content/assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins ExtraLight";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("/content/assets/fonts/Poppins-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Material Symbols Outlined";
  src: url("/content/assets/fonts/MaterialIconsOutlined-Regular.otf") format("opentype");
}
.material-symbols-outlined {
  direction: ltr;
  display: inline-block;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  font-family: "Material Symbols Outlined";
}

.mt-0 {
  margin-top: 0rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.m-0 {
  margin: 0rem !important;
}

.p-0 {
  padding: 0rem !important;
}

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.mt-1 {
  margin-top: 0.3125rem !important;
}

.pt-1 {
  padding-top: 0.3125rem !important;
}

.mr-1 {
  margin-right: 0.3125rem !important;
}

.pr-1 {
  padding-right: 0.3125rem !important;
}

.mb-1 {
  margin-bottom: 0.3125rem !important;
}

.pb-1 {
  padding-bottom: 0.3125rem !important;
}

.ml-1 {
  margin-left: 0.3125rem !important;
}

.pl-1 {
  padding-left: 0.3125rem !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.my-1 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

.py-1 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.mx-1 {
  margin-left: 0.3125rem !important;
  margin-right: 0.3125rem !important;
}

.px-1 {
  padding-left: 0.3125rem !important;
  padding-right: 0.3125rem !important;
}

.mt-2 {
  margin-top: 0.625rem !important;
}

.pt-2 {
  padding-top: 0.625rem !important;
}

.mr-2 {
  margin-right: 0.625rem !important;
}

.pr-2 {
  padding-right: 0.625rem !important;
}

.mb-2 {
  margin-bottom: 0.625rem !important;
}

.pb-2 {
  padding-bottom: 0.625rem !important;
}

.ml-2 {
  margin-left: 0.625rem !important;
}

.pl-2 {
  padding-left: 0.625rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.my-2 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.py-2 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.mx-2 {
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important;
}

.px-2 {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.mt-3 {
  margin-top: 0.9375rem !important;
}

.pt-3 {
  padding-top: 0.9375rem !important;
}

.mr-3 {
  margin-right: 0.9375rem !important;
}

.pr-3 {
  padding-right: 0.9375rem !important;
}

.mb-3 {
  margin-bottom: 0.9375rem !important;
}

.pb-3 {
  padding-bottom: 0.9375rem !important;
}

.ml-3 {
  margin-left: 0.9375rem !important;
}

.pl-3 {
  padding-left: 0.9375rem !important;
}

.m-3 {
  margin: 0.9375rem !important;
}

.p-3 {
  padding: 0.9375rem !important;
}

.my-3 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important;
}

.py-3 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important;
}

.mx-3 {
  margin-left: 0.9375rem !important;
  margin-right: 0.9375rem !important;
}

.px-3 {
  padding-left: 0.9375rem !important;
  padding-right: 0.9375rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.mr-4 {
  margin-right: 1.25rem !important;
}

.pr-4 {
  padding-right: 1.25rem !important;
}

.mb-4 {
  margin-bottom: 1.25rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.ml-4 {
  margin-left: 1.25rem !important;
}

.pl-4 {
  padding-left: 1.25rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.my-4 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.py-4 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.mx-4 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.px-4 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.5625rem !important;
}

.pt-5 {
  padding-top: 1.5625rem !important;
}

.mr-5 {
  margin-right: 1.5625rem !important;
}

.pr-5 {
  padding-right: 1.5625rem !important;
}

.mb-5 {
  margin-bottom: 1.5625rem !important;
}

.pb-5 {
  padding-bottom: 1.5625rem !important;
}

.ml-5 {
  margin-left: 1.5625rem !important;
}

.pl-5 {
  padding-left: 1.5625rem !important;
}

.m-5 {
  margin: 1.5625rem !important;
}

.p-5 {
  padding: 1.5625rem !important;
}

.my-5 {
  margin-top: 1.5625rem !important;
  margin-bottom: 1.5625rem !important;
}

.py-5 {
  padding-top: 1.5625rem !important;
  padding-bottom: 1.5625rem !important;
}

.mx-5 {
  margin-left: 1.5625rem !important;
  margin-right: 1.5625rem !important;
}

.px-5 {
  padding-left: 1.5625rem !important;
  padding-right: 1.5625rem !important;
}

.mt-6 {
  margin-top: 1.875rem !important;
}

.pt-6 {
  padding-top: 1.875rem !important;
}

.mr-6 {
  margin-right: 1.875rem !important;
}

.pr-6 {
  padding-right: 1.875rem !important;
}

.mb-6 {
  margin-bottom: 1.875rem !important;
}

.pb-6 {
  padding-bottom: 1.875rem !important;
}

.ml-6 {
  margin-left: 1.875rem !important;
}

.pl-6 {
  padding-left: 1.875rem !important;
}

.m-6 {
  margin: 1.875rem !important;
}

.p-6 {
  padding: 1.875rem !important;
}

.my-6 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.py-6 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.mx-6 {
  margin-left: 1.875rem !important;
  margin-right: 1.875rem !important;
}

.px-6 {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
}

.mt-7 {
  margin-top: 2.1875rem !important;
}

.pt-7 {
  padding-top: 2.1875rem !important;
}

.mr-7 {
  margin-right: 2.1875rem !important;
}

.pr-7 {
  padding-right: 2.1875rem !important;
}

.mb-7 {
  margin-bottom: 2.1875rem !important;
}

.pb-7 {
  padding-bottom: 2.1875rem !important;
}

.ml-7 {
  margin-left: 2.1875rem !important;
}

.pl-7 {
  padding-left: 2.1875rem !important;
}

.m-7 {
  margin: 2.1875rem !important;
}

.p-7 {
  padding: 2.1875rem !important;
}

.my-7 {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important;
}

.py-7 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important;
}

.mx-7 {
  margin-left: 2.1875rem !important;
  margin-right: 2.1875rem !important;
}

.px-7 {
  padding-left: 2.1875rem !important;
  padding-right: 2.1875rem !important;
}

.mt-8 {
  margin-top: 2.5rem !important;
}

.pt-8 {
  padding-top: 2.5rem !important;
}

.mr-8 {
  margin-right: 2.5rem !important;
}

.pr-8 {
  padding-right: 2.5rem !important;
}

.mb-8 {
  margin-bottom: 2.5rem !important;
}

.pb-8 {
  padding-bottom: 2.5rem !important;
}

.ml-8 {
  margin-left: 2.5rem !important;
}

.pl-8 {
  padding-left: 2.5rem !important;
}

.m-8 {
  margin: 2.5rem !important;
}

.p-8 {
  padding: 2.5rem !important;
}

.my-8 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.py-8 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.mx-8 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.px-8 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.mt-9 {
  margin-top: 2.8125rem !important;
}

.pt-9 {
  padding-top: 2.8125rem !important;
}

.mr-9 {
  margin-right: 2.8125rem !important;
}

.pr-9 {
  padding-right: 2.8125rem !important;
}

.mb-9 {
  margin-bottom: 2.8125rem !important;
}

.pb-9 {
  padding-bottom: 2.8125rem !important;
}

.ml-9 {
  margin-left: 2.8125rem !important;
}

.pl-9 {
  padding-left: 2.8125rem !important;
}

.m-9 {
  margin: 2.8125rem !important;
}

.p-9 {
  padding: 2.8125rem !important;
}

.my-9 {
  margin-top: 2.8125rem !important;
  margin-bottom: 2.8125rem !important;
}

.py-9 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important;
}

.mx-9 {
  margin-left: 2.8125rem !important;
  margin-right: 2.8125rem !important;
}

.px-9 {
  padding-left: 2.8125rem !important;
  padding-right: 2.8125rem !important;
}

.mt-10 {
  margin-top: 3.125rem !important;
}

.pt-10 {
  padding-top: 3.125rem !important;
}

.mr-10 {
  margin-right: 3.125rem !important;
}

.pr-10 {
  padding-right: 3.125rem !important;
}

.mb-10 {
  margin-bottom: 3.125rem !important;
}

.pb-10 {
  padding-bottom: 3.125rem !important;
}

.ml-10 {
  margin-left: 3.125rem !important;
}

.pl-10 {
  padding-left: 3.125rem !important;
}

.m-10 {
  margin: 3.125rem !important;
}

.p-10 {
  padding: 3.125rem !important;
}

.my-10 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.py-10 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.mx-10 {
  margin-left: 3.125rem !important;
  margin-right: 3.125rem !important;
}

.px-10 {
  padding-left: 3.125rem !important;
  padding-right: 3.125rem !important;
}

.mt-11 {
  margin-top: 3.4375rem !important;
}

.pt-11 {
  padding-top: 3.4375rem !important;
}

.mr-11 {
  margin-right: 3.4375rem !important;
}

.pr-11 {
  padding-right: 3.4375rem !important;
}

.mb-11 {
  margin-bottom: 3.4375rem !important;
}

.pb-11 {
  padding-bottom: 3.4375rem !important;
}

.ml-11 {
  margin-left: 3.4375rem !important;
}

.pl-11 {
  padding-left: 3.4375rem !important;
}

.m-11 {
  margin: 3.4375rem !important;
}

.p-11 {
  padding: 3.4375rem !important;
}

.my-11 {
  margin-top: 3.4375rem !important;
  margin-bottom: 3.4375rem !important;
}

.py-11 {
  padding-top: 3.4375rem !important;
  padding-bottom: 3.4375rem !important;
}

.mx-11 {
  margin-left: 3.4375rem !important;
  margin-right: 3.4375rem !important;
}

.px-11 {
  padding-left: 3.4375rem !important;
  padding-right: 3.4375rem !important;
}

.mt-12 {
  margin-top: 3.75rem !important;
}

.pt-12 {
  padding-top: 3.75rem !important;
}

.mr-12 {
  margin-right: 3.75rem !important;
}

.pr-12 {
  padding-right: 3.75rem !important;
}

.mb-12 {
  margin-bottom: 3.75rem !important;
}

.pb-12 {
  padding-bottom: 3.75rem !important;
}

.ml-12 {
  margin-left: 3.75rem !important;
}

.pl-12 {
  padding-left: 3.75rem !important;
}

.m-12 {
  margin: 3.75rem !important;
}

.p-12 {
  padding: 3.75rem !important;
}

.my-12 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.py-12 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.mx-12 {
  margin-left: 3.75rem !important;
  margin-right: 3.75rem !important;
}

.px-12 {
  padding-left: 3.75rem !important;
  padding-right: 3.75rem !important;
}

.mt-13 {
  margin-top: 4.0625rem !important;
}

.pt-13 {
  padding-top: 4.0625rem !important;
}

.mr-13 {
  margin-right: 4.0625rem !important;
}

.pr-13 {
  padding-right: 4.0625rem !important;
}

.mb-13 {
  margin-bottom: 4.0625rem !important;
}

.pb-13 {
  padding-bottom: 4.0625rem !important;
}

.ml-13 {
  margin-left: 4.0625rem !important;
}

.pl-13 {
  padding-left: 4.0625rem !important;
}

.m-13 {
  margin: 4.0625rem !important;
}

.p-13 {
  padding: 4.0625rem !important;
}

.my-13 {
  margin-top: 4.0625rem !important;
  margin-bottom: 4.0625rem !important;
}

.py-13 {
  padding-top: 4.0625rem !important;
  padding-bottom: 4.0625rem !important;
}

.mx-13 {
  margin-left: 4.0625rem !important;
  margin-right: 4.0625rem !important;
}

.px-13 {
  padding-left: 4.0625rem !important;
  padding-right: 4.0625rem !important;
}

.mt-14 {
  margin-top: 4.375rem !important;
}

.pt-14 {
  padding-top: 4.375rem !important;
}

.mr-14 {
  margin-right: 4.375rem !important;
}

.pr-14 {
  padding-right: 4.375rem !important;
}

.mb-14 {
  margin-bottom: 4.375rem !important;
}

.pb-14 {
  padding-bottom: 4.375rem !important;
}

.ml-14 {
  margin-left: 4.375rem !important;
}

.pl-14 {
  padding-left: 4.375rem !important;
}

.m-14 {
  margin: 4.375rem !important;
}

.p-14 {
  padding: 4.375rem !important;
}

.my-14 {
  margin-top: 4.375rem !important;
  margin-bottom: 4.375rem !important;
}

.py-14 {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important;
}

.mx-14 {
  margin-left: 4.375rem !important;
  margin-right: 4.375rem !important;
}

.px-14 {
  padding-left: 4.375rem !important;
  padding-right: 4.375rem !important;
}

.mt-15 {
  margin-top: 4.6875rem !important;
}

.pt-15 {
  padding-top: 4.6875rem !important;
}

.mr-15 {
  margin-right: 4.6875rem !important;
}

.pr-15 {
  padding-right: 4.6875rem !important;
}

.mb-15 {
  margin-bottom: 4.6875rem !important;
}

.pb-15 {
  padding-bottom: 4.6875rem !important;
}

.ml-15 {
  margin-left: 4.6875rem !important;
}

.pl-15 {
  padding-left: 4.6875rem !important;
}

.m-15 {
  margin: 4.6875rem !important;
}

.p-15 {
  padding: 4.6875rem !important;
}

.my-15 {
  margin-top: 4.6875rem !important;
  margin-bottom: 4.6875rem !important;
}

.py-15 {
  padding-top: 4.6875rem !important;
  padding-bottom: 4.6875rem !important;
}

.mx-15 {
  margin-left: 4.6875rem !important;
  margin-right: 4.6875rem !important;
}

.px-15 {
  padding-left: 4.6875rem !important;
  padding-right: 4.6875rem !important;
}

.mt-16 {
  margin-top: 5rem !important;
}

.pt-16 {
  padding-top: 5rem !important;
}

.mr-16 {
  margin-right: 5rem !important;
}

.pr-16 {
  padding-right: 5rem !important;
}

.mb-16 {
  margin-bottom: 5rem !important;
}

.pb-16 {
  padding-bottom: 5rem !important;
}

.ml-16 {
  margin-left: 5rem !important;
}

.pl-16 {
  padding-left: 5rem !important;
}

.m-16 {
  margin: 5rem !important;
}

.p-16 {
  padding: 5rem !important;
}

.my-16 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.py-16 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.mx-16 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.px-16 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.mt-17 {
  margin-top: 5.3125rem !important;
}

.pt-17 {
  padding-top: 5.3125rem !important;
}

.mr-17 {
  margin-right: 5.3125rem !important;
}

.pr-17 {
  padding-right: 5.3125rem !important;
}

.mb-17 {
  margin-bottom: 5.3125rem !important;
}

.pb-17 {
  padding-bottom: 5.3125rem !important;
}

.ml-17 {
  margin-left: 5.3125rem !important;
}

.pl-17 {
  padding-left: 5.3125rem !important;
}

.m-17 {
  margin: 5.3125rem !important;
}

.p-17 {
  padding: 5.3125rem !important;
}

.my-17 {
  margin-top: 5.3125rem !important;
  margin-bottom: 5.3125rem !important;
}

.py-17 {
  padding-top: 5.3125rem !important;
  padding-bottom: 5.3125rem !important;
}

.mx-17 {
  margin-left: 5.3125rem !important;
  margin-right: 5.3125rem !important;
}

.px-17 {
  padding-left: 5.3125rem !important;
  padding-right: 5.3125rem !important;
}

.mt-18 {
  margin-top: 5.625rem !important;
}

.pt-18 {
  padding-top: 5.625rem !important;
}

.mr-18 {
  margin-right: 5.625rem !important;
}

.pr-18 {
  padding-right: 5.625rem !important;
}

.mb-18 {
  margin-bottom: 5.625rem !important;
}

.pb-18 {
  padding-bottom: 5.625rem !important;
}

.ml-18 {
  margin-left: 5.625rem !important;
}

.pl-18 {
  padding-left: 5.625rem !important;
}

.m-18 {
  margin: 5.625rem !important;
}

.p-18 {
  padding: 5.625rem !important;
}

.my-18 {
  margin-top: 5.625rem !important;
  margin-bottom: 5.625rem !important;
}

.py-18 {
  padding-top: 5.625rem !important;
  padding-bottom: 5.625rem !important;
}

.mx-18 {
  margin-left: 5.625rem !important;
  margin-right: 5.625rem !important;
}

.px-18 {
  padding-left: 5.625rem !important;
  padding-right: 5.625rem !important;
}

.mt-19 {
  margin-top: 5.9375rem !important;
}

.pt-19 {
  padding-top: 5.9375rem !important;
}

.mr-19 {
  margin-right: 5.9375rem !important;
}

.pr-19 {
  padding-right: 5.9375rem !important;
}

.mb-19 {
  margin-bottom: 5.9375rem !important;
}

.pb-19 {
  padding-bottom: 5.9375rem !important;
}

.ml-19 {
  margin-left: 5.9375rem !important;
}

.pl-19 {
  padding-left: 5.9375rem !important;
}

.m-19 {
  margin: 5.9375rem !important;
}

.p-19 {
  padding: 5.9375rem !important;
}

.my-19 {
  margin-top: 5.9375rem !important;
  margin-bottom: 5.9375rem !important;
}

.py-19 {
  padding-top: 5.9375rem !important;
  padding-bottom: 5.9375rem !important;
}

.mx-19 {
  margin-left: 5.9375rem !important;
  margin-right: 5.9375rem !important;
}

.px-19 {
  padding-left: 5.9375rem !important;
  padding-right: 5.9375rem !important;
}

.mt-20 {
  margin-top: 6.25rem !important;
}

.pt-20 {
  padding-top: 6.25rem !important;
}

.mr-20 {
  margin-right: 6.25rem !important;
}

.pr-20 {
  padding-right: 6.25rem !important;
}

.mb-20 {
  margin-bottom: 6.25rem !important;
}

.pb-20 {
  padding-bottom: 6.25rem !important;
}

.ml-20 {
  margin-left: 6.25rem !important;
}

.pl-20 {
  padding-left: 6.25rem !important;
}

.m-20 {
  margin: 6.25rem !important;
}

.p-20 {
  padding: 6.25rem !important;
}

.my-20 {
  margin-top: 6.25rem !important;
  margin-bottom: 6.25rem !important;
}

.py-20 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

.mx-20 {
  margin-left: 6.25rem !important;
  margin-right: 6.25rem !important;
}

.px-20 {
  padding-left: 6.25rem !important;
  padding-right: 6.25rem !important;
}

.mt-21 {
  margin-top: 6.5625rem !important;
}

.pt-21 {
  padding-top: 6.5625rem !important;
}

.mr-21 {
  margin-right: 6.5625rem !important;
}

.pr-21 {
  padding-right: 6.5625rem !important;
}

.mb-21 {
  margin-bottom: 6.5625rem !important;
}

.pb-21 {
  padding-bottom: 6.5625rem !important;
}

.ml-21 {
  margin-left: 6.5625rem !important;
}

.pl-21 {
  padding-left: 6.5625rem !important;
}

.m-21 {
  margin: 6.5625rem !important;
}

.p-21 {
  padding: 6.5625rem !important;
}

.my-21 {
  margin-top: 6.5625rem !important;
  margin-bottom: 6.5625rem !important;
}

.py-21 {
  padding-top: 6.5625rem !important;
  padding-bottom: 6.5625rem !important;
}

.mx-21 {
  margin-left: 6.5625rem !important;
  margin-right: 6.5625rem !important;
}

.px-21 {
  padding-left: 6.5625rem !important;
  padding-right: 6.5625rem !important;
}

.mt-22 {
  margin-top: 6.875rem !important;
}

.pt-22 {
  padding-top: 6.875rem !important;
}

.mr-22 {
  margin-right: 6.875rem !important;
}

.pr-22 {
  padding-right: 6.875rem !important;
}

.mb-22 {
  margin-bottom: 6.875rem !important;
}

.pb-22 {
  padding-bottom: 6.875rem !important;
}

.ml-22 {
  margin-left: 6.875rem !important;
}

.pl-22 {
  padding-left: 6.875rem !important;
}

.m-22 {
  margin: 6.875rem !important;
}

.p-22 {
  padding: 6.875rem !important;
}

.my-22 {
  margin-top: 6.875rem !important;
  margin-bottom: 6.875rem !important;
}

.py-22 {
  padding-top: 6.875rem !important;
  padding-bottom: 6.875rem !important;
}

.mx-22 {
  margin-left: 6.875rem !important;
  margin-right: 6.875rem !important;
}

.px-22 {
  padding-left: 6.875rem !important;
  padding-right: 6.875rem !important;
}

.mt-23 {
  margin-top: 7.1875rem !important;
}

.pt-23 {
  padding-top: 7.1875rem !important;
}

.mr-23 {
  margin-right: 7.1875rem !important;
}

.pr-23 {
  padding-right: 7.1875rem !important;
}

.mb-23 {
  margin-bottom: 7.1875rem !important;
}

.pb-23 {
  padding-bottom: 7.1875rem !important;
}

.ml-23 {
  margin-left: 7.1875rem !important;
}

.pl-23 {
  padding-left: 7.1875rem !important;
}

.m-23 {
  margin: 7.1875rem !important;
}

.p-23 {
  padding: 7.1875rem !important;
}

.my-23 {
  margin-top: 7.1875rem !important;
  margin-bottom: 7.1875rem !important;
}

.py-23 {
  padding-top: 7.1875rem !important;
  padding-bottom: 7.1875rem !important;
}

.mx-23 {
  margin-left: 7.1875rem !important;
  margin-right: 7.1875rem !important;
}

.px-23 {
  padding-left: 7.1875rem !important;
  padding-right: 7.1875rem !important;
}

.mt-24 {
  margin-top: 7.5rem !important;
}

.pt-24 {
  padding-top: 7.5rem !important;
}

.mr-24 {
  margin-right: 7.5rem !important;
}

.pr-24 {
  padding-right: 7.5rem !important;
}

.mb-24 {
  margin-bottom: 7.5rem !important;
}

.pb-24 {
  padding-bottom: 7.5rem !important;
}

.ml-24 {
  margin-left: 7.5rem !important;
}

.pl-24 {
  padding-left: 7.5rem !important;
}

.m-24 {
  margin: 7.5rem !important;
}

.p-24 {
  padding: 7.5rem !important;
}

.my-24 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.py-24 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.mx-24 {
  margin-left: 7.5rem !important;
  margin-right: 7.5rem !important;
}

.px-24 {
  padding-left: 7.5rem !important;
  padding-right: 7.5rem !important;
}

.mt-25 {
  margin-top: 7.8125rem !important;
}

.pt-25 {
  padding-top: 7.8125rem !important;
}

.mr-25 {
  margin-right: 7.8125rem !important;
}

.pr-25 {
  padding-right: 7.8125rem !important;
}

.mb-25 {
  margin-bottom: 7.8125rem !important;
}

.pb-25 {
  padding-bottom: 7.8125rem !important;
}

.ml-25 {
  margin-left: 7.8125rem !important;
}

.pl-25 {
  padding-left: 7.8125rem !important;
}

.m-25 {
  margin: 7.8125rem !important;
}

.p-25 {
  padding: 7.8125rem !important;
}

.my-25 {
  margin-top: 7.8125rem !important;
  margin-bottom: 7.8125rem !important;
}

.py-25 {
  padding-top: 7.8125rem !important;
  padding-bottom: 7.8125rem !important;
}

.mx-25 {
  margin-left: 7.8125rem !important;
  margin-right: 7.8125rem !important;
}

.px-25 {
  padding-left: 7.8125rem !important;
  padding-right: 7.8125rem !important;
}

.mt-26 {
  margin-top: 8.125rem !important;
}

.pt-26 {
  padding-top: 8.125rem !important;
}

.mr-26 {
  margin-right: 8.125rem !important;
}

.pr-26 {
  padding-right: 8.125rem !important;
}

.mb-26 {
  margin-bottom: 8.125rem !important;
}

.pb-26 {
  padding-bottom: 8.125rem !important;
}

.ml-26 {
  margin-left: 8.125rem !important;
}

.pl-26 {
  padding-left: 8.125rem !important;
}

.m-26 {
  margin: 8.125rem !important;
}

.p-26 {
  padding: 8.125rem !important;
}

.my-26 {
  margin-top: 8.125rem !important;
  margin-bottom: 8.125rem !important;
}

.py-26 {
  padding-top: 8.125rem !important;
  padding-bottom: 8.125rem !important;
}

.mx-26 {
  margin-left: 8.125rem !important;
  margin-right: 8.125rem !important;
}

.px-26 {
  padding-left: 8.125rem !important;
  padding-right: 8.125rem !important;
}

.mt-27 {
  margin-top: 8.4375rem !important;
}

.pt-27 {
  padding-top: 8.4375rem !important;
}

.mr-27 {
  margin-right: 8.4375rem !important;
}

.pr-27 {
  padding-right: 8.4375rem !important;
}

.mb-27 {
  margin-bottom: 8.4375rem !important;
}

.pb-27 {
  padding-bottom: 8.4375rem !important;
}

.ml-27 {
  margin-left: 8.4375rem !important;
}

.pl-27 {
  padding-left: 8.4375rem !important;
}

.m-27 {
  margin: 8.4375rem !important;
}

.p-27 {
  padding: 8.4375rem !important;
}

.my-27 {
  margin-top: 8.4375rem !important;
  margin-bottom: 8.4375rem !important;
}

.py-27 {
  padding-top: 8.4375rem !important;
  padding-bottom: 8.4375rem !important;
}

.mx-27 {
  margin-left: 8.4375rem !important;
  margin-right: 8.4375rem !important;
}

.px-27 {
  padding-left: 8.4375rem !important;
  padding-right: 8.4375rem !important;
}

.mt-28 {
  margin-top: 8.75rem !important;
}

.pt-28 {
  padding-top: 8.75rem !important;
}

.mr-28 {
  margin-right: 8.75rem !important;
}

.pr-28 {
  padding-right: 8.75rem !important;
}

.mb-28 {
  margin-bottom: 8.75rem !important;
}

.pb-28 {
  padding-bottom: 8.75rem !important;
}

.ml-28 {
  margin-left: 8.75rem !important;
}

.pl-28 {
  padding-left: 8.75rem !important;
}

.m-28 {
  margin: 8.75rem !important;
}

.p-28 {
  padding: 8.75rem !important;
}

.my-28 {
  margin-top: 8.75rem !important;
  margin-bottom: 8.75rem !important;
}

.py-28 {
  padding-top: 8.75rem !important;
  padding-bottom: 8.75rem !important;
}

.mx-28 {
  margin-left: 8.75rem !important;
  margin-right: 8.75rem !important;
}

.px-28 {
  padding-left: 8.75rem !important;
  padding-right: 8.75rem !important;
}

.mt-29 {
  margin-top: 9.0625rem !important;
}

.pt-29 {
  padding-top: 9.0625rem !important;
}

.mr-29 {
  margin-right: 9.0625rem !important;
}

.pr-29 {
  padding-right: 9.0625rem !important;
}

.mb-29 {
  margin-bottom: 9.0625rem !important;
}

.pb-29 {
  padding-bottom: 9.0625rem !important;
}

.ml-29 {
  margin-left: 9.0625rem !important;
}

.pl-29 {
  padding-left: 9.0625rem !important;
}

.m-29 {
  margin: 9.0625rem !important;
}

.p-29 {
  padding: 9.0625rem !important;
}

.my-29 {
  margin-top: 9.0625rem !important;
  margin-bottom: 9.0625rem !important;
}

.py-29 {
  padding-top: 9.0625rem !important;
  padding-bottom: 9.0625rem !important;
}

.mx-29 {
  margin-left: 9.0625rem !important;
  margin-right: 9.0625rem !important;
}

.px-29 {
  padding-left: 9.0625rem !important;
  padding-right: 9.0625rem !important;
}

.mt-30 {
  margin-top: 9.375rem !important;
}

.pt-30 {
  padding-top: 9.375rem !important;
}

.mr-30 {
  margin-right: 9.375rem !important;
}

.pr-30 {
  padding-right: 9.375rem !important;
}

.mb-30 {
  margin-bottom: 9.375rem !important;
}

.pb-30 {
  padding-bottom: 9.375rem !important;
}

.ml-30 {
  margin-left: 9.375rem !important;
}

.pl-30 {
  padding-left: 9.375rem !important;
}

.m-30 {
  margin: 9.375rem !important;
}

.p-30 {
  padding: 9.375rem !important;
}

.my-30 {
  margin-top: 9.375rem !important;
  margin-bottom: 9.375rem !important;
}

.py-30 {
  padding-top: 9.375rem !important;
  padding-bottom: 9.375rem !important;
}

.mx-30 {
  margin-left: 9.375rem !important;
  margin-right: 9.375rem !important;
}

.px-30 {
  padding-left: 9.375rem !important;
  padding-right: 9.375rem !important;
}

.mt-31 {
  margin-top: 9.6875rem !important;
}

.pt-31 {
  padding-top: 9.6875rem !important;
}

.mr-31 {
  margin-right: 9.6875rem !important;
}

.pr-31 {
  padding-right: 9.6875rem !important;
}

.mb-31 {
  margin-bottom: 9.6875rem !important;
}

.pb-31 {
  padding-bottom: 9.6875rem !important;
}

.ml-31 {
  margin-left: 9.6875rem !important;
}

.pl-31 {
  padding-left: 9.6875rem !important;
}

.m-31 {
  margin: 9.6875rem !important;
}

.p-31 {
  padding: 9.6875rem !important;
}

.my-31 {
  margin-top: 9.6875rem !important;
  margin-bottom: 9.6875rem !important;
}

.py-31 {
  padding-top: 9.6875rem !important;
  padding-bottom: 9.6875rem !important;
}

.mx-31 {
  margin-left: 9.6875rem !important;
  margin-right: 9.6875rem !important;
}

.px-31 {
  padding-left: 9.6875rem !important;
  padding-right: 9.6875rem !important;
}

.mt-32 {
  margin-top: 10rem !important;
}

.pt-32 {
  padding-top: 10rem !important;
}

.mr-32 {
  margin-right: 10rem !important;
}

.pr-32 {
  padding-right: 10rem !important;
}

.mb-32 {
  margin-bottom: 10rem !important;
}

.pb-32 {
  padding-bottom: 10rem !important;
}

.ml-32 {
  margin-left: 10rem !important;
}

.pl-32 {
  padding-left: 10rem !important;
}

.m-32 {
  margin: 10rem !important;
}

.p-32 {
  padding: 10rem !important;
}

.my-32 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.py-32 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.mx-32 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.px-32 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.mt-33 {
  margin-top: 10.3125rem !important;
}

.pt-33 {
  padding-top: 10.3125rem !important;
}

.mr-33 {
  margin-right: 10.3125rem !important;
}

.pr-33 {
  padding-right: 10.3125rem !important;
}

.mb-33 {
  margin-bottom: 10.3125rem !important;
}

.pb-33 {
  padding-bottom: 10.3125rem !important;
}

.ml-33 {
  margin-left: 10.3125rem !important;
}

.pl-33 {
  padding-left: 10.3125rem !important;
}

.m-33 {
  margin: 10.3125rem !important;
}

.p-33 {
  padding: 10.3125rem !important;
}

.my-33 {
  margin-top: 10.3125rem !important;
  margin-bottom: 10.3125rem !important;
}

.py-33 {
  padding-top: 10.3125rem !important;
  padding-bottom: 10.3125rem !important;
}

.mx-33 {
  margin-left: 10.3125rem !important;
  margin-right: 10.3125rem !important;
}

.px-33 {
  padding-left: 10.3125rem !important;
  padding-right: 10.3125rem !important;
}

.mt-34 {
  margin-top: 10.625rem !important;
}

.pt-34 {
  padding-top: 10.625rem !important;
}

.mr-34 {
  margin-right: 10.625rem !important;
}

.pr-34 {
  padding-right: 10.625rem !important;
}

.mb-34 {
  margin-bottom: 10.625rem !important;
}

.pb-34 {
  padding-bottom: 10.625rem !important;
}

.ml-34 {
  margin-left: 10.625rem !important;
}

.pl-34 {
  padding-left: 10.625rem !important;
}

.m-34 {
  margin: 10.625rem !important;
}

.p-34 {
  padding: 10.625rem !important;
}

.my-34 {
  margin-top: 10.625rem !important;
  margin-bottom: 10.625rem !important;
}

.py-34 {
  padding-top: 10.625rem !important;
  padding-bottom: 10.625rem !important;
}

.mx-34 {
  margin-left: 10.625rem !important;
  margin-right: 10.625rem !important;
}

.px-34 {
  padding-left: 10.625rem !important;
  padding-right: 10.625rem !important;
}

.mt-35 {
  margin-top: 10.9375rem !important;
}

.pt-35 {
  padding-top: 10.9375rem !important;
}

.mr-35 {
  margin-right: 10.9375rem !important;
}

.pr-35 {
  padding-right: 10.9375rem !important;
}

.mb-35 {
  margin-bottom: 10.9375rem !important;
}

.pb-35 {
  padding-bottom: 10.9375rem !important;
}

.ml-35 {
  margin-left: 10.9375rem !important;
}

.pl-35 {
  padding-left: 10.9375rem !important;
}

.m-35 {
  margin: 10.9375rem !important;
}

.p-35 {
  padding: 10.9375rem !important;
}

.my-35 {
  margin-top: 10.9375rem !important;
  margin-bottom: 10.9375rem !important;
}

.py-35 {
  padding-top: 10.9375rem !important;
  padding-bottom: 10.9375rem !important;
}

.mx-35 {
  margin-left: 10.9375rem !important;
  margin-right: 10.9375rem !important;
}

.px-35 {
  padding-left: 10.9375rem !important;
  padding-right: 10.9375rem !important;
}

.mt-36 {
  margin-top: 11.25rem !important;
}

.pt-36 {
  padding-top: 11.25rem !important;
}

.mr-36 {
  margin-right: 11.25rem !important;
}

.pr-36 {
  padding-right: 11.25rem !important;
}

.mb-36 {
  margin-bottom: 11.25rem !important;
}

.pb-36 {
  padding-bottom: 11.25rem !important;
}

.ml-36 {
  margin-left: 11.25rem !important;
}

.pl-36 {
  padding-left: 11.25rem !important;
}

.m-36 {
  margin: 11.25rem !important;
}

.p-36 {
  padding: 11.25rem !important;
}

.my-36 {
  margin-top: 11.25rem !important;
  margin-bottom: 11.25rem !important;
}

.py-36 {
  padding-top: 11.25rem !important;
  padding-bottom: 11.25rem !important;
}

.mx-36 {
  margin-left: 11.25rem !important;
  margin-right: 11.25rem !important;
}

.px-36 {
  padding-left: 11.25rem !important;
  padding-right: 11.25rem !important;
}

.mt-37 {
  margin-top: 11.5625rem !important;
}

.pt-37 {
  padding-top: 11.5625rem !important;
}

.mr-37 {
  margin-right: 11.5625rem !important;
}

.pr-37 {
  padding-right: 11.5625rem !important;
}

.mb-37 {
  margin-bottom: 11.5625rem !important;
}

.pb-37 {
  padding-bottom: 11.5625rem !important;
}

.ml-37 {
  margin-left: 11.5625rem !important;
}

.pl-37 {
  padding-left: 11.5625rem !important;
}

.m-37 {
  margin: 11.5625rem !important;
}

.p-37 {
  padding: 11.5625rem !important;
}

.my-37 {
  margin-top: 11.5625rem !important;
  margin-bottom: 11.5625rem !important;
}

.py-37 {
  padding-top: 11.5625rem !important;
  padding-bottom: 11.5625rem !important;
}

.mx-37 {
  margin-left: 11.5625rem !important;
  margin-right: 11.5625rem !important;
}

.px-37 {
  padding-left: 11.5625rem !important;
  padding-right: 11.5625rem !important;
}

.mt-38 {
  margin-top: 11.875rem !important;
}

.pt-38 {
  padding-top: 11.875rem !important;
}

.mr-38 {
  margin-right: 11.875rem !important;
}

.pr-38 {
  padding-right: 11.875rem !important;
}

.mb-38 {
  margin-bottom: 11.875rem !important;
}

.pb-38 {
  padding-bottom: 11.875rem !important;
}

.ml-38 {
  margin-left: 11.875rem !important;
}

.pl-38 {
  padding-left: 11.875rem !important;
}

.m-38 {
  margin: 11.875rem !important;
}

.p-38 {
  padding: 11.875rem !important;
}

.my-38 {
  margin-top: 11.875rem !important;
  margin-bottom: 11.875rem !important;
}

.py-38 {
  padding-top: 11.875rem !important;
  padding-bottom: 11.875rem !important;
}

.mx-38 {
  margin-left: 11.875rem !important;
  margin-right: 11.875rem !important;
}

.px-38 {
  padding-left: 11.875rem !important;
  padding-right: 11.875rem !important;
}

.mt-39 {
  margin-top: 12.1875rem !important;
}

.pt-39 {
  padding-top: 12.1875rem !important;
}

.mr-39 {
  margin-right: 12.1875rem !important;
}

.pr-39 {
  padding-right: 12.1875rem !important;
}

.mb-39 {
  margin-bottom: 12.1875rem !important;
}

.pb-39 {
  padding-bottom: 12.1875rem !important;
}

.ml-39 {
  margin-left: 12.1875rem !important;
}

.pl-39 {
  padding-left: 12.1875rem !important;
}

.m-39 {
  margin: 12.1875rem !important;
}

.p-39 {
  padding: 12.1875rem !important;
}

.my-39 {
  margin-top: 12.1875rem !important;
  margin-bottom: 12.1875rem !important;
}

.py-39 {
  padding-top: 12.1875rem !important;
  padding-bottom: 12.1875rem !important;
}

.mx-39 {
  margin-left: 12.1875rem !important;
  margin-right: 12.1875rem !important;
}

.px-39 {
  padding-left: 12.1875rem !important;
  padding-right: 12.1875rem !important;
}

.mt-40 {
  margin-top: 12.5rem !important;
}

.pt-40 {
  padding-top: 12.5rem !important;
}

.mr-40 {
  margin-right: 12.5rem !important;
}

.pr-40 {
  padding-right: 12.5rem !important;
}

.mb-40 {
  margin-bottom: 12.5rem !important;
}

.pb-40 {
  padding-bottom: 12.5rem !important;
}

.ml-40 {
  margin-left: 12.5rem !important;
}

.pl-40 {
  padding-left: 12.5rem !important;
}

.m-40 {
  margin: 12.5rem !important;
}

.p-40 {
  padding: 12.5rem !important;
}

.my-40 {
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important;
}

.py-40 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important;
}

.mx-40 {
  margin-left: 12.5rem !important;
  margin-right: 12.5rem !important;
}

.px-40 {
  padding-left: 12.5rem !important;
  padding-right: 12.5rem !important;
}

.mt-41 {
  margin-top: 12.8125rem !important;
}

.pt-41 {
  padding-top: 12.8125rem !important;
}

.mr-41 {
  margin-right: 12.8125rem !important;
}

.pr-41 {
  padding-right: 12.8125rem !important;
}

.mb-41 {
  margin-bottom: 12.8125rem !important;
}

.pb-41 {
  padding-bottom: 12.8125rem !important;
}

.ml-41 {
  margin-left: 12.8125rem !important;
}

.pl-41 {
  padding-left: 12.8125rem !important;
}

.m-41 {
  margin: 12.8125rem !important;
}

.p-41 {
  padding: 12.8125rem !important;
}

.my-41 {
  margin-top: 12.8125rem !important;
  margin-bottom: 12.8125rem !important;
}

.py-41 {
  padding-top: 12.8125rem !important;
  padding-bottom: 12.8125rem !important;
}

.mx-41 {
  margin-left: 12.8125rem !important;
  margin-right: 12.8125rem !important;
}

.px-41 {
  padding-left: 12.8125rem !important;
  padding-right: 12.8125rem !important;
}

.mt-42 {
  margin-top: 13.125rem !important;
}

.pt-42 {
  padding-top: 13.125rem !important;
}

.mr-42 {
  margin-right: 13.125rem !important;
}

.pr-42 {
  padding-right: 13.125rem !important;
}

.mb-42 {
  margin-bottom: 13.125rem !important;
}

.pb-42 {
  padding-bottom: 13.125rem !important;
}

.ml-42 {
  margin-left: 13.125rem !important;
}

.pl-42 {
  padding-left: 13.125rem !important;
}

.m-42 {
  margin: 13.125rem !important;
}

.p-42 {
  padding: 13.125rem !important;
}

.my-42 {
  margin-top: 13.125rem !important;
  margin-bottom: 13.125rem !important;
}

.py-42 {
  padding-top: 13.125rem !important;
  padding-bottom: 13.125rem !important;
}

.mx-42 {
  margin-left: 13.125rem !important;
  margin-right: 13.125rem !important;
}

.px-42 {
  padding-left: 13.125rem !important;
  padding-right: 13.125rem !important;
}

.mt-43 {
  margin-top: 13.4375rem !important;
}

.pt-43 {
  padding-top: 13.4375rem !important;
}

.mr-43 {
  margin-right: 13.4375rem !important;
}

.pr-43 {
  padding-right: 13.4375rem !important;
}

.mb-43 {
  margin-bottom: 13.4375rem !important;
}

.pb-43 {
  padding-bottom: 13.4375rem !important;
}

.ml-43 {
  margin-left: 13.4375rem !important;
}

.pl-43 {
  padding-left: 13.4375rem !important;
}

.m-43 {
  margin: 13.4375rem !important;
}

.p-43 {
  padding: 13.4375rem !important;
}

.my-43 {
  margin-top: 13.4375rem !important;
  margin-bottom: 13.4375rem !important;
}

.py-43 {
  padding-top: 13.4375rem !important;
  padding-bottom: 13.4375rem !important;
}

.mx-43 {
  margin-left: 13.4375rem !important;
  margin-right: 13.4375rem !important;
}

.px-43 {
  padding-left: 13.4375rem !important;
  padding-right: 13.4375rem !important;
}

.mt-44 {
  margin-top: 13.75rem !important;
}

.pt-44 {
  padding-top: 13.75rem !important;
}

.mr-44 {
  margin-right: 13.75rem !important;
}

.pr-44 {
  padding-right: 13.75rem !important;
}

.mb-44 {
  margin-bottom: 13.75rem !important;
}

.pb-44 {
  padding-bottom: 13.75rem !important;
}

.ml-44 {
  margin-left: 13.75rem !important;
}

.pl-44 {
  padding-left: 13.75rem !important;
}

.m-44 {
  margin: 13.75rem !important;
}

.p-44 {
  padding: 13.75rem !important;
}

.my-44 {
  margin-top: 13.75rem !important;
  margin-bottom: 13.75rem !important;
}

.py-44 {
  padding-top: 13.75rem !important;
  padding-bottom: 13.75rem !important;
}

.mx-44 {
  margin-left: 13.75rem !important;
  margin-right: 13.75rem !important;
}

.px-44 {
  padding-left: 13.75rem !important;
  padding-right: 13.75rem !important;
}

.mt-45 {
  margin-top: 14.0625rem !important;
}

.pt-45 {
  padding-top: 14.0625rem !important;
}

.mr-45 {
  margin-right: 14.0625rem !important;
}

.pr-45 {
  padding-right: 14.0625rem !important;
}

.mb-45 {
  margin-bottom: 14.0625rem !important;
}

.pb-45 {
  padding-bottom: 14.0625rem !important;
}

.ml-45 {
  margin-left: 14.0625rem !important;
}

.pl-45 {
  padding-left: 14.0625rem !important;
}

.m-45 {
  margin: 14.0625rem !important;
}

.p-45 {
  padding: 14.0625rem !important;
}

.my-45 {
  margin-top: 14.0625rem !important;
  margin-bottom: 14.0625rem !important;
}

.py-45 {
  padding-top: 14.0625rem !important;
  padding-bottom: 14.0625rem !important;
}

.mx-45 {
  margin-left: 14.0625rem !important;
  margin-right: 14.0625rem !important;
}

.px-45 {
  padding-left: 14.0625rem !important;
  padding-right: 14.0625rem !important;
}

.mt-46 {
  margin-top: 14.375rem !important;
}

.pt-46 {
  padding-top: 14.375rem !important;
}

.mr-46 {
  margin-right: 14.375rem !important;
}

.pr-46 {
  padding-right: 14.375rem !important;
}

.mb-46 {
  margin-bottom: 14.375rem !important;
}

.pb-46 {
  padding-bottom: 14.375rem !important;
}

.ml-46 {
  margin-left: 14.375rem !important;
}

.pl-46 {
  padding-left: 14.375rem !important;
}

.m-46 {
  margin: 14.375rem !important;
}

.p-46 {
  padding: 14.375rem !important;
}

.my-46 {
  margin-top: 14.375rem !important;
  margin-bottom: 14.375rem !important;
}

.py-46 {
  padding-top: 14.375rem !important;
  padding-bottom: 14.375rem !important;
}

.mx-46 {
  margin-left: 14.375rem !important;
  margin-right: 14.375rem !important;
}

.px-46 {
  padding-left: 14.375rem !important;
  padding-right: 14.375rem !important;
}

.mt-47 {
  margin-top: 14.6875rem !important;
}

.pt-47 {
  padding-top: 14.6875rem !important;
}

.mr-47 {
  margin-right: 14.6875rem !important;
}

.pr-47 {
  padding-right: 14.6875rem !important;
}

.mb-47 {
  margin-bottom: 14.6875rem !important;
}

.pb-47 {
  padding-bottom: 14.6875rem !important;
}

.ml-47 {
  margin-left: 14.6875rem !important;
}

.pl-47 {
  padding-left: 14.6875rem !important;
}

.m-47 {
  margin: 14.6875rem !important;
}

.p-47 {
  padding: 14.6875rem !important;
}

.my-47 {
  margin-top: 14.6875rem !important;
  margin-bottom: 14.6875rem !important;
}

.py-47 {
  padding-top: 14.6875rem !important;
  padding-bottom: 14.6875rem !important;
}

.mx-47 {
  margin-left: 14.6875rem !important;
  margin-right: 14.6875rem !important;
}

.px-47 {
  padding-left: 14.6875rem !important;
  padding-right: 14.6875rem !important;
}

.mt-48 {
  margin-top: 15rem !important;
}

.pt-48 {
  padding-top: 15rem !important;
}

.mr-48 {
  margin-right: 15rem !important;
}

.pr-48 {
  padding-right: 15rem !important;
}

.mb-48 {
  margin-bottom: 15rem !important;
}

.pb-48 {
  padding-bottom: 15rem !important;
}

.ml-48 {
  margin-left: 15rem !important;
}

.pl-48 {
  padding-left: 15rem !important;
}

.m-48 {
  margin: 15rem !important;
}

.p-48 {
  padding: 15rem !important;
}

.my-48 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.py-48 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.mx-48 {
  margin-left: 15rem !important;
  margin-right: 15rem !important;
}

.px-48 {
  padding-left: 15rem !important;
  padding-right: 15rem !important;
}

.mt-49 {
  margin-top: 15.3125rem !important;
}

.pt-49 {
  padding-top: 15.3125rem !important;
}

.mr-49 {
  margin-right: 15.3125rem !important;
}

.pr-49 {
  padding-right: 15.3125rem !important;
}

.mb-49 {
  margin-bottom: 15.3125rem !important;
}

.pb-49 {
  padding-bottom: 15.3125rem !important;
}

.ml-49 {
  margin-left: 15.3125rem !important;
}

.pl-49 {
  padding-left: 15.3125rem !important;
}

.m-49 {
  margin: 15.3125rem !important;
}

.p-49 {
  padding: 15.3125rem !important;
}

.my-49 {
  margin-top: 15.3125rem !important;
  margin-bottom: 15.3125rem !important;
}

.py-49 {
  padding-top: 15.3125rem !important;
  padding-bottom: 15.3125rem !important;
}

.mx-49 {
  margin-left: 15.3125rem !important;
  margin-right: 15.3125rem !important;
}

.px-49 {
  padding-left: 15.3125rem !important;
  padding-right: 15.3125rem !important;
}

.mt-50 {
  margin-top: 15.625rem !important;
}

.pt-50 {
  padding-top: 15.625rem !important;
}

.mr-50 {
  margin-right: 15.625rem !important;
}

.pr-50 {
  padding-right: 15.625rem !important;
}

.mb-50 {
  margin-bottom: 15.625rem !important;
}

.pb-50 {
  padding-bottom: 15.625rem !important;
}

.ml-50 {
  margin-left: 15.625rem !important;
}

.pl-50 {
  padding-left: 15.625rem !important;
}

.m-50 {
  margin: 15.625rem !important;
}

.p-50 {
  padding: 15.625rem !important;
}

.my-50 {
  margin-top: 15.625rem !important;
  margin-bottom: 15.625rem !important;
}

.py-50 {
  padding-top: 15.625rem !important;
  padding-bottom: 15.625rem !important;
}

.mx-50 {
  margin-left: 15.625rem !important;
  margin-right: 15.625rem !important;
}

.px-50 {
  padding-left: 15.625rem !important;
  padding-right: 15.625rem !important;
}

.mt-51 {
  margin-top: 15.9375rem !important;
}

.pt-51 {
  padding-top: 15.9375rem !important;
}

.mr-51 {
  margin-right: 15.9375rem !important;
}

.pr-51 {
  padding-right: 15.9375rem !important;
}

.mb-51 {
  margin-bottom: 15.9375rem !important;
}

.pb-51 {
  padding-bottom: 15.9375rem !important;
}

.ml-51 {
  margin-left: 15.9375rem !important;
}

.pl-51 {
  padding-left: 15.9375rem !important;
}

.m-51 {
  margin: 15.9375rem !important;
}

.p-51 {
  padding: 15.9375rem !important;
}

.my-51 {
  margin-top: 15.9375rem !important;
  margin-bottom: 15.9375rem !important;
}

.py-51 {
  padding-top: 15.9375rem !important;
  padding-bottom: 15.9375rem !important;
}

.mx-51 {
  margin-left: 15.9375rem !important;
  margin-right: 15.9375rem !important;
}

.px-51 {
  padding-left: 15.9375rem !important;
  padding-right: 15.9375rem !important;
}

.mt-52 {
  margin-top: 16.25rem !important;
}

.pt-52 {
  padding-top: 16.25rem !important;
}

.mr-52 {
  margin-right: 16.25rem !important;
}

.pr-52 {
  padding-right: 16.25rem !important;
}

.mb-52 {
  margin-bottom: 16.25rem !important;
}

.pb-52 {
  padding-bottom: 16.25rem !important;
}

.ml-52 {
  margin-left: 16.25rem !important;
}

.pl-52 {
  padding-left: 16.25rem !important;
}

.m-52 {
  margin: 16.25rem !important;
}

.p-52 {
  padding: 16.25rem !important;
}

.my-52 {
  margin-top: 16.25rem !important;
  margin-bottom: 16.25rem !important;
}

.py-52 {
  padding-top: 16.25rem !important;
  padding-bottom: 16.25rem !important;
}

.mx-52 {
  margin-left: 16.25rem !important;
  margin-right: 16.25rem !important;
}

.px-52 {
  padding-left: 16.25rem !important;
  padding-right: 16.25rem !important;
}

.mt-53 {
  margin-top: 16.5625rem !important;
}

.pt-53 {
  padding-top: 16.5625rem !important;
}

.mr-53 {
  margin-right: 16.5625rem !important;
}

.pr-53 {
  padding-right: 16.5625rem !important;
}

.mb-53 {
  margin-bottom: 16.5625rem !important;
}

.pb-53 {
  padding-bottom: 16.5625rem !important;
}

.ml-53 {
  margin-left: 16.5625rem !important;
}

.pl-53 {
  padding-left: 16.5625rem !important;
}

.m-53 {
  margin: 16.5625rem !important;
}

.p-53 {
  padding: 16.5625rem !important;
}

.my-53 {
  margin-top: 16.5625rem !important;
  margin-bottom: 16.5625rem !important;
}

.py-53 {
  padding-top: 16.5625rem !important;
  padding-bottom: 16.5625rem !important;
}

.mx-53 {
  margin-left: 16.5625rem !important;
  margin-right: 16.5625rem !important;
}

.px-53 {
  padding-left: 16.5625rem !important;
  padding-right: 16.5625rem !important;
}

.mt-54 {
  margin-top: 16.875rem !important;
}

.pt-54 {
  padding-top: 16.875rem !important;
}

.mr-54 {
  margin-right: 16.875rem !important;
}

.pr-54 {
  padding-right: 16.875rem !important;
}

.mb-54 {
  margin-bottom: 16.875rem !important;
}

.pb-54 {
  padding-bottom: 16.875rem !important;
}

.ml-54 {
  margin-left: 16.875rem !important;
}

.pl-54 {
  padding-left: 16.875rem !important;
}

.m-54 {
  margin: 16.875rem !important;
}

.p-54 {
  padding: 16.875rem !important;
}

.my-54 {
  margin-top: 16.875rem !important;
  margin-bottom: 16.875rem !important;
}

.py-54 {
  padding-top: 16.875rem !important;
  padding-bottom: 16.875rem !important;
}

.mx-54 {
  margin-left: 16.875rem !important;
  margin-right: 16.875rem !important;
}

.px-54 {
  padding-left: 16.875rem !important;
  padding-right: 16.875rem !important;
}

.mt-55 {
  margin-top: 17.1875rem !important;
}

.pt-55 {
  padding-top: 17.1875rem !important;
}

.mr-55 {
  margin-right: 17.1875rem !important;
}

.pr-55 {
  padding-right: 17.1875rem !important;
}

.mb-55 {
  margin-bottom: 17.1875rem !important;
}

.pb-55 {
  padding-bottom: 17.1875rem !important;
}

.ml-55 {
  margin-left: 17.1875rem !important;
}

.pl-55 {
  padding-left: 17.1875rem !important;
}

.m-55 {
  margin: 17.1875rem !important;
}

.p-55 {
  padding: 17.1875rem !important;
}

.my-55 {
  margin-top: 17.1875rem !important;
  margin-bottom: 17.1875rem !important;
}

.py-55 {
  padding-top: 17.1875rem !important;
  padding-bottom: 17.1875rem !important;
}

.mx-55 {
  margin-left: 17.1875rem !important;
  margin-right: 17.1875rem !important;
}

.px-55 {
  padding-left: 17.1875rem !important;
  padding-right: 17.1875rem !important;
}

.mt-56 {
  margin-top: 17.5rem !important;
}

.pt-56 {
  padding-top: 17.5rem !important;
}

.mr-56 {
  margin-right: 17.5rem !important;
}

.pr-56 {
  padding-right: 17.5rem !important;
}

.mb-56 {
  margin-bottom: 17.5rem !important;
}

.pb-56 {
  padding-bottom: 17.5rem !important;
}

.ml-56 {
  margin-left: 17.5rem !important;
}

.pl-56 {
  padding-left: 17.5rem !important;
}

.m-56 {
  margin: 17.5rem !important;
}

.p-56 {
  padding: 17.5rem !important;
}

.my-56 {
  margin-top: 17.5rem !important;
  margin-bottom: 17.5rem !important;
}

.py-56 {
  padding-top: 17.5rem !important;
  padding-bottom: 17.5rem !important;
}

.mx-56 {
  margin-left: 17.5rem !important;
  margin-right: 17.5rem !important;
}

.px-56 {
  padding-left: 17.5rem !important;
  padding-right: 17.5rem !important;
}

.mt-57 {
  margin-top: 17.8125rem !important;
}

.pt-57 {
  padding-top: 17.8125rem !important;
}

.mr-57 {
  margin-right: 17.8125rem !important;
}

.pr-57 {
  padding-right: 17.8125rem !important;
}

.mb-57 {
  margin-bottom: 17.8125rem !important;
}

.pb-57 {
  padding-bottom: 17.8125rem !important;
}

.ml-57 {
  margin-left: 17.8125rem !important;
}

.pl-57 {
  padding-left: 17.8125rem !important;
}

.m-57 {
  margin: 17.8125rem !important;
}

.p-57 {
  padding: 17.8125rem !important;
}

.my-57 {
  margin-top: 17.8125rem !important;
  margin-bottom: 17.8125rem !important;
}

.py-57 {
  padding-top: 17.8125rem !important;
  padding-bottom: 17.8125rem !important;
}

.mx-57 {
  margin-left: 17.8125rem !important;
  margin-right: 17.8125rem !important;
}

.px-57 {
  padding-left: 17.8125rem !important;
  padding-right: 17.8125rem !important;
}

.mt-58 {
  margin-top: 18.125rem !important;
}

.pt-58 {
  padding-top: 18.125rem !important;
}

.mr-58 {
  margin-right: 18.125rem !important;
}

.pr-58 {
  padding-right: 18.125rem !important;
}

.mb-58 {
  margin-bottom: 18.125rem !important;
}

.pb-58 {
  padding-bottom: 18.125rem !important;
}

.ml-58 {
  margin-left: 18.125rem !important;
}

.pl-58 {
  padding-left: 18.125rem !important;
}

.m-58 {
  margin: 18.125rem !important;
}

.p-58 {
  padding: 18.125rem !important;
}

.my-58 {
  margin-top: 18.125rem !important;
  margin-bottom: 18.125rem !important;
}

.py-58 {
  padding-top: 18.125rem !important;
  padding-bottom: 18.125rem !important;
}

.mx-58 {
  margin-left: 18.125rem !important;
  margin-right: 18.125rem !important;
}

.px-58 {
  padding-left: 18.125rem !important;
  padding-right: 18.125rem !important;
}

.mt-59 {
  margin-top: 18.4375rem !important;
}

.pt-59 {
  padding-top: 18.4375rem !important;
}

.mr-59 {
  margin-right: 18.4375rem !important;
}

.pr-59 {
  padding-right: 18.4375rem !important;
}

.mb-59 {
  margin-bottom: 18.4375rem !important;
}

.pb-59 {
  padding-bottom: 18.4375rem !important;
}

.ml-59 {
  margin-left: 18.4375rem !important;
}

.pl-59 {
  padding-left: 18.4375rem !important;
}

.m-59 {
  margin: 18.4375rem !important;
}

.p-59 {
  padding: 18.4375rem !important;
}

.my-59 {
  margin-top: 18.4375rem !important;
  margin-bottom: 18.4375rem !important;
}

.py-59 {
  padding-top: 18.4375rem !important;
  padding-bottom: 18.4375rem !important;
}

.mx-59 {
  margin-left: 18.4375rem !important;
  margin-right: 18.4375rem !important;
}

.px-59 {
  padding-left: 18.4375rem !important;
  padding-right: 18.4375rem !important;
}

.mt-60 {
  margin-top: 18.75rem !important;
}

.pt-60 {
  padding-top: 18.75rem !important;
}

.mr-60 {
  margin-right: 18.75rem !important;
}

.pr-60 {
  padding-right: 18.75rem !important;
}

.mb-60 {
  margin-bottom: 18.75rem !important;
}

.pb-60 {
  padding-bottom: 18.75rem !important;
}

.ml-60 {
  margin-left: 18.75rem !important;
}

.pl-60 {
  padding-left: 18.75rem !important;
}

.m-60 {
  margin: 18.75rem !important;
}

.p-60 {
  padding: 18.75rem !important;
}

.my-60 {
  margin-top: 18.75rem !important;
  margin-bottom: 18.75rem !important;
}

.py-60 {
  padding-top: 18.75rem !important;
  padding-bottom: 18.75rem !important;
}

.mx-60 {
  margin-left: 18.75rem !important;
  margin-right: 18.75rem !important;
}

.px-60 {
  padding-left: 18.75rem !important;
  padding-right: 18.75rem !important;
}

.mt-61 {
  margin-top: 19.0625rem !important;
}

.pt-61 {
  padding-top: 19.0625rem !important;
}

.mr-61 {
  margin-right: 19.0625rem !important;
}

.pr-61 {
  padding-right: 19.0625rem !important;
}

.mb-61 {
  margin-bottom: 19.0625rem !important;
}

.pb-61 {
  padding-bottom: 19.0625rem !important;
}

.ml-61 {
  margin-left: 19.0625rem !important;
}

.pl-61 {
  padding-left: 19.0625rem !important;
}

.m-61 {
  margin: 19.0625rem !important;
}

.p-61 {
  padding: 19.0625rem !important;
}

.my-61 {
  margin-top: 19.0625rem !important;
  margin-bottom: 19.0625rem !important;
}

.py-61 {
  padding-top: 19.0625rem !important;
  padding-bottom: 19.0625rem !important;
}

.mx-61 {
  margin-left: 19.0625rem !important;
  margin-right: 19.0625rem !important;
}

.px-61 {
  padding-left: 19.0625rem !important;
  padding-right: 19.0625rem !important;
}

.mt-62 {
  margin-top: 19.375rem !important;
}

.pt-62 {
  padding-top: 19.375rem !important;
}

.mr-62 {
  margin-right: 19.375rem !important;
}

.pr-62 {
  padding-right: 19.375rem !important;
}

.mb-62 {
  margin-bottom: 19.375rem !important;
}

.pb-62 {
  padding-bottom: 19.375rem !important;
}

.ml-62 {
  margin-left: 19.375rem !important;
}

.pl-62 {
  padding-left: 19.375rem !important;
}

.m-62 {
  margin: 19.375rem !important;
}

.p-62 {
  padding: 19.375rem !important;
}

.my-62 {
  margin-top: 19.375rem !important;
  margin-bottom: 19.375rem !important;
}

.py-62 {
  padding-top: 19.375rem !important;
  padding-bottom: 19.375rem !important;
}

.mx-62 {
  margin-left: 19.375rem !important;
  margin-right: 19.375rem !important;
}

.px-62 {
  padding-left: 19.375rem !important;
  padding-right: 19.375rem !important;
}

.mt-63 {
  margin-top: 19.6875rem !important;
}

.pt-63 {
  padding-top: 19.6875rem !important;
}

.mr-63 {
  margin-right: 19.6875rem !important;
}

.pr-63 {
  padding-right: 19.6875rem !important;
}

.mb-63 {
  margin-bottom: 19.6875rem !important;
}

.pb-63 {
  padding-bottom: 19.6875rem !important;
}

.ml-63 {
  margin-left: 19.6875rem !important;
}

.pl-63 {
  padding-left: 19.6875rem !important;
}

.m-63 {
  margin: 19.6875rem !important;
}

.p-63 {
  padding: 19.6875rem !important;
}

.my-63 {
  margin-top: 19.6875rem !important;
  margin-bottom: 19.6875rem !important;
}

.py-63 {
  padding-top: 19.6875rem !important;
  padding-bottom: 19.6875rem !important;
}

.mx-63 {
  margin-left: 19.6875rem !important;
  margin-right: 19.6875rem !important;
}

.px-63 {
  padding-left: 19.6875rem !important;
  padding-right: 19.6875rem !important;
}

.mt-64 {
  margin-top: 20rem !important;
}

.pt-64 {
  padding-top: 20rem !important;
}

.mr-64 {
  margin-right: 20rem !important;
}

.pr-64 {
  padding-right: 20rem !important;
}

.mb-64 {
  margin-bottom: 20rem !important;
}

.pb-64 {
  padding-bottom: 20rem !important;
}

.ml-64 {
  margin-left: 20rem !important;
}

.pl-64 {
  padding-left: 20rem !important;
}

.m-64 {
  margin: 20rem !important;
}

.p-64 {
  padding: 20rem !important;
}

.my-64 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.py-64 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.mx-64 {
  margin-left: 20rem !important;
  margin-right: 20rem !important;
}

.px-64 {
  padding-left: 20rem !important;
  padding-right: 20rem !important;
}

.mt-65 {
  margin-top: 20.3125rem !important;
}

.pt-65 {
  padding-top: 20.3125rem !important;
}

.mr-65 {
  margin-right: 20.3125rem !important;
}

.pr-65 {
  padding-right: 20.3125rem !important;
}

.mb-65 {
  margin-bottom: 20.3125rem !important;
}

.pb-65 {
  padding-bottom: 20.3125rem !important;
}

.ml-65 {
  margin-left: 20.3125rem !important;
}

.pl-65 {
  padding-left: 20.3125rem !important;
}

.m-65 {
  margin: 20.3125rem !important;
}

.p-65 {
  padding: 20.3125rem !important;
}

.my-65 {
  margin-top: 20.3125rem !important;
  margin-bottom: 20.3125rem !important;
}

.py-65 {
  padding-top: 20.3125rem !important;
  padding-bottom: 20.3125rem !important;
}

.mx-65 {
  margin-left: 20.3125rem !important;
  margin-right: 20.3125rem !important;
}

.px-65 {
  padding-left: 20.3125rem !important;
  padding-right: 20.3125rem !important;
}

.mt-66 {
  margin-top: 20.625rem !important;
}

.pt-66 {
  padding-top: 20.625rem !important;
}

.mr-66 {
  margin-right: 20.625rem !important;
}

.pr-66 {
  padding-right: 20.625rem !important;
}

.mb-66 {
  margin-bottom: 20.625rem !important;
}

.pb-66 {
  padding-bottom: 20.625rem !important;
}

.ml-66 {
  margin-left: 20.625rem !important;
}

.pl-66 {
  padding-left: 20.625rem !important;
}

.m-66 {
  margin: 20.625rem !important;
}

.p-66 {
  padding: 20.625rem !important;
}

.my-66 {
  margin-top: 20.625rem !important;
  margin-bottom: 20.625rem !important;
}

.py-66 {
  padding-top: 20.625rem !important;
  padding-bottom: 20.625rem !important;
}

.mx-66 {
  margin-left: 20.625rem !important;
  margin-right: 20.625rem !important;
}

.px-66 {
  padding-left: 20.625rem !important;
  padding-right: 20.625rem !important;
}

.mt-67 {
  margin-top: 20.9375rem !important;
}

.pt-67 {
  padding-top: 20.9375rem !important;
}

.mr-67 {
  margin-right: 20.9375rem !important;
}

.pr-67 {
  padding-right: 20.9375rem !important;
}

.mb-67 {
  margin-bottom: 20.9375rem !important;
}

.pb-67 {
  padding-bottom: 20.9375rem !important;
}

.ml-67 {
  margin-left: 20.9375rem !important;
}

.pl-67 {
  padding-left: 20.9375rem !important;
}

.m-67 {
  margin: 20.9375rem !important;
}

.p-67 {
  padding: 20.9375rem !important;
}

.my-67 {
  margin-top: 20.9375rem !important;
  margin-bottom: 20.9375rem !important;
}

.py-67 {
  padding-top: 20.9375rem !important;
  padding-bottom: 20.9375rem !important;
}

.mx-67 {
  margin-left: 20.9375rem !important;
  margin-right: 20.9375rem !important;
}

.px-67 {
  padding-left: 20.9375rem !important;
  padding-right: 20.9375rem !important;
}

.mt-68 {
  margin-top: 21.25rem !important;
}

.pt-68 {
  padding-top: 21.25rem !important;
}

.mr-68 {
  margin-right: 21.25rem !important;
}

.pr-68 {
  padding-right: 21.25rem !important;
}

.mb-68 {
  margin-bottom: 21.25rem !important;
}

.pb-68 {
  padding-bottom: 21.25rem !important;
}

.ml-68 {
  margin-left: 21.25rem !important;
}

.pl-68 {
  padding-left: 21.25rem !important;
}

.m-68 {
  margin: 21.25rem !important;
}

.p-68 {
  padding: 21.25rem !important;
}

.my-68 {
  margin-top: 21.25rem !important;
  margin-bottom: 21.25rem !important;
}

.py-68 {
  padding-top: 21.25rem !important;
  padding-bottom: 21.25rem !important;
}

.mx-68 {
  margin-left: 21.25rem !important;
  margin-right: 21.25rem !important;
}

.px-68 {
  padding-left: 21.25rem !important;
  padding-right: 21.25rem !important;
}

.mt-69 {
  margin-top: 21.5625rem !important;
}

.pt-69 {
  padding-top: 21.5625rem !important;
}

.mr-69 {
  margin-right: 21.5625rem !important;
}

.pr-69 {
  padding-right: 21.5625rem !important;
}

.mb-69 {
  margin-bottom: 21.5625rem !important;
}

.pb-69 {
  padding-bottom: 21.5625rem !important;
}

.ml-69 {
  margin-left: 21.5625rem !important;
}

.pl-69 {
  padding-left: 21.5625rem !important;
}

.m-69 {
  margin: 21.5625rem !important;
}

.p-69 {
  padding: 21.5625rem !important;
}

.my-69 {
  margin-top: 21.5625rem !important;
  margin-bottom: 21.5625rem !important;
}

.py-69 {
  padding-top: 21.5625rem !important;
  padding-bottom: 21.5625rem !important;
}

.mx-69 {
  margin-left: 21.5625rem !important;
  margin-right: 21.5625rem !important;
}

.px-69 {
  padding-left: 21.5625rem !important;
  padding-right: 21.5625rem !important;
}

.mt-70 {
  margin-top: 21.875rem !important;
}

.pt-70 {
  padding-top: 21.875rem !important;
}

.mr-70 {
  margin-right: 21.875rem !important;
}

.pr-70 {
  padding-right: 21.875rem !important;
}

.mb-70 {
  margin-bottom: 21.875rem !important;
}

.pb-70 {
  padding-bottom: 21.875rem !important;
}

.ml-70 {
  margin-left: 21.875rem !important;
}

.pl-70 {
  padding-left: 21.875rem !important;
}

.m-70 {
  margin: 21.875rem !important;
}

.p-70 {
  padding: 21.875rem !important;
}

.my-70 {
  margin-top: 21.875rem !important;
  margin-bottom: 21.875rem !important;
}

.py-70 {
  padding-top: 21.875rem !important;
  padding-bottom: 21.875rem !important;
}

.mx-70 {
  margin-left: 21.875rem !important;
  margin-right: 21.875rem !important;
}

.px-70 {
  padding-left: 21.875rem !important;
  padding-right: 21.875rem !important;
}

.mt-71 {
  margin-top: 22.1875rem !important;
}

.pt-71 {
  padding-top: 22.1875rem !important;
}

.mr-71 {
  margin-right: 22.1875rem !important;
}

.pr-71 {
  padding-right: 22.1875rem !important;
}

.mb-71 {
  margin-bottom: 22.1875rem !important;
}

.pb-71 {
  padding-bottom: 22.1875rem !important;
}

.ml-71 {
  margin-left: 22.1875rem !important;
}

.pl-71 {
  padding-left: 22.1875rem !important;
}

.m-71 {
  margin: 22.1875rem !important;
}

.p-71 {
  padding: 22.1875rem !important;
}

.my-71 {
  margin-top: 22.1875rem !important;
  margin-bottom: 22.1875rem !important;
}

.py-71 {
  padding-top: 22.1875rem !important;
  padding-bottom: 22.1875rem !important;
}

.mx-71 {
  margin-left: 22.1875rem !important;
  margin-right: 22.1875rem !important;
}

.px-71 {
  padding-left: 22.1875rem !important;
  padding-right: 22.1875rem !important;
}

.mt-72 {
  margin-top: 22.5rem !important;
}

.pt-72 {
  padding-top: 22.5rem !important;
}

.mr-72 {
  margin-right: 22.5rem !important;
}

.pr-72 {
  padding-right: 22.5rem !important;
}

.mb-72 {
  margin-bottom: 22.5rem !important;
}

.pb-72 {
  padding-bottom: 22.5rem !important;
}

.ml-72 {
  margin-left: 22.5rem !important;
}

.pl-72 {
  padding-left: 22.5rem !important;
}

.m-72 {
  margin: 22.5rem !important;
}

.p-72 {
  padding: 22.5rem !important;
}

.my-72 {
  margin-top: 22.5rem !important;
  margin-bottom: 22.5rem !important;
}

.py-72 {
  padding-top: 22.5rem !important;
  padding-bottom: 22.5rem !important;
}

.mx-72 {
  margin-left: 22.5rem !important;
  margin-right: 22.5rem !important;
}

.px-72 {
  padding-left: 22.5rem !important;
  padding-right: 22.5rem !important;
}

.mt-73 {
  margin-top: 22.8125rem !important;
}

.pt-73 {
  padding-top: 22.8125rem !important;
}

.mr-73 {
  margin-right: 22.8125rem !important;
}

.pr-73 {
  padding-right: 22.8125rem !important;
}

.mb-73 {
  margin-bottom: 22.8125rem !important;
}

.pb-73 {
  padding-bottom: 22.8125rem !important;
}

.ml-73 {
  margin-left: 22.8125rem !important;
}

.pl-73 {
  padding-left: 22.8125rem !important;
}

.m-73 {
  margin: 22.8125rem !important;
}

.p-73 {
  padding: 22.8125rem !important;
}

.my-73 {
  margin-top: 22.8125rem !important;
  margin-bottom: 22.8125rem !important;
}

.py-73 {
  padding-top: 22.8125rem !important;
  padding-bottom: 22.8125rem !important;
}

.mx-73 {
  margin-left: 22.8125rem !important;
  margin-right: 22.8125rem !important;
}

.px-73 {
  padding-left: 22.8125rem !important;
  padding-right: 22.8125rem !important;
}

.mt-74 {
  margin-top: 23.125rem !important;
}

.pt-74 {
  padding-top: 23.125rem !important;
}

.mr-74 {
  margin-right: 23.125rem !important;
}

.pr-74 {
  padding-right: 23.125rem !important;
}

.mb-74 {
  margin-bottom: 23.125rem !important;
}

.pb-74 {
  padding-bottom: 23.125rem !important;
}

.ml-74 {
  margin-left: 23.125rem !important;
}

.pl-74 {
  padding-left: 23.125rem !important;
}

.m-74 {
  margin: 23.125rem !important;
}

.p-74 {
  padding: 23.125rem !important;
}

.my-74 {
  margin-top: 23.125rem !important;
  margin-bottom: 23.125rem !important;
}

.py-74 {
  padding-top: 23.125rem !important;
  padding-bottom: 23.125rem !important;
}

.mx-74 {
  margin-left: 23.125rem !important;
  margin-right: 23.125rem !important;
}

.px-74 {
  padding-left: 23.125rem !important;
  padding-right: 23.125rem !important;
}

.mt-75 {
  margin-top: 23.4375rem !important;
}

.pt-75 {
  padding-top: 23.4375rem !important;
}

.mr-75 {
  margin-right: 23.4375rem !important;
}

.pr-75 {
  padding-right: 23.4375rem !important;
}

.mb-75 {
  margin-bottom: 23.4375rem !important;
}

.pb-75 {
  padding-bottom: 23.4375rem !important;
}

.ml-75 {
  margin-left: 23.4375rem !important;
}

.pl-75 {
  padding-left: 23.4375rem !important;
}

.m-75 {
  margin: 23.4375rem !important;
}

.p-75 {
  padding: 23.4375rem !important;
}

.my-75 {
  margin-top: 23.4375rem !important;
  margin-bottom: 23.4375rem !important;
}

.py-75 {
  padding-top: 23.4375rem !important;
  padding-bottom: 23.4375rem !important;
}

.mx-75 {
  margin-left: 23.4375rem !important;
  margin-right: 23.4375rem !important;
}

.px-75 {
  padding-left: 23.4375rem !important;
  padding-right: 23.4375rem !important;
}

.mt-76 {
  margin-top: 23.75rem !important;
}

.pt-76 {
  padding-top: 23.75rem !important;
}

.mr-76 {
  margin-right: 23.75rem !important;
}

.pr-76 {
  padding-right: 23.75rem !important;
}

.mb-76 {
  margin-bottom: 23.75rem !important;
}

.pb-76 {
  padding-bottom: 23.75rem !important;
}

.ml-76 {
  margin-left: 23.75rem !important;
}

.pl-76 {
  padding-left: 23.75rem !important;
}

.m-76 {
  margin: 23.75rem !important;
}

.p-76 {
  padding: 23.75rem !important;
}

.my-76 {
  margin-top: 23.75rem !important;
  margin-bottom: 23.75rem !important;
}

.py-76 {
  padding-top: 23.75rem !important;
  padding-bottom: 23.75rem !important;
}

.mx-76 {
  margin-left: 23.75rem !important;
  margin-right: 23.75rem !important;
}

.px-76 {
  padding-left: 23.75rem !important;
  padding-right: 23.75rem !important;
}

.mt-77 {
  margin-top: 24.0625rem !important;
}

.pt-77 {
  padding-top: 24.0625rem !important;
}

.mr-77 {
  margin-right: 24.0625rem !important;
}

.pr-77 {
  padding-right: 24.0625rem !important;
}

.mb-77 {
  margin-bottom: 24.0625rem !important;
}

.pb-77 {
  padding-bottom: 24.0625rem !important;
}

.ml-77 {
  margin-left: 24.0625rem !important;
}

.pl-77 {
  padding-left: 24.0625rem !important;
}

.m-77 {
  margin: 24.0625rem !important;
}

.p-77 {
  padding: 24.0625rem !important;
}

.my-77 {
  margin-top: 24.0625rem !important;
  margin-bottom: 24.0625rem !important;
}

.py-77 {
  padding-top: 24.0625rem !important;
  padding-bottom: 24.0625rem !important;
}

.mx-77 {
  margin-left: 24.0625rem !important;
  margin-right: 24.0625rem !important;
}

.px-77 {
  padding-left: 24.0625rem !important;
  padding-right: 24.0625rem !important;
}

.mt-78 {
  margin-top: 24.375rem !important;
}

.pt-78 {
  padding-top: 24.375rem !important;
}

.mr-78 {
  margin-right: 24.375rem !important;
}

.pr-78 {
  padding-right: 24.375rem !important;
}

.mb-78 {
  margin-bottom: 24.375rem !important;
}

.pb-78 {
  padding-bottom: 24.375rem !important;
}

.ml-78 {
  margin-left: 24.375rem !important;
}

.pl-78 {
  padding-left: 24.375rem !important;
}

.m-78 {
  margin: 24.375rem !important;
}

.p-78 {
  padding: 24.375rem !important;
}

.my-78 {
  margin-top: 24.375rem !important;
  margin-bottom: 24.375rem !important;
}

.py-78 {
  padding-top: 24.375rem !important;
  padding-bottom: 24.375rem !important;
}

.mx-78 {
  margin-left: 24.375rem !important;
  margin-right: 24.375rem !important;
}

.px-78 {
  padding-left: 24.375rem !important;
  padding-right: 24.375rem !important;
}

.mt-79 {
  margin-top: 24.6875rem !important;
}

.pt-79 {
  padding-top: 24.6875rem !important;
}

.mr-79 {
  margin-right: 24.6875rem !important;
}

.pr-79 {
  padding-right: 24.6875rem !important;
}

.mb-79 {
  margin-bottom: 24.6875rem !important;
}

.pb-79 {
  padding-bottom: 24.6875rem !important;
}

.ml-79 {
  margin-left: 24.6875rem !important;
}

.pl-79 {
  padding-left: 24.6875rem !important;
}

.m-79 {
  margin: 24.6875rem !important;
}

.p-79 {
  padding: 24.6875rem !important;
}

.my-79 {
  margin-top: 24.6875rem !important;
  margin-bottom: 24.6875rem !important;
}

.py-79 {
  padding-top: 24.6875rem !important;
  padding-bottom: 24.6875rem !important;
}

.mx-79 {
  margin-left: 24.6875rem !important;
  margin-right: 24.6875rem !important;
}

.px-79 {
  padding-left: 24.6875rem !important;
  padding-right: 24.6875rem !important;
}

.mt-80 {
  margin-top: 25rem !important;
}

.pt-80 {
  padding-top: 25rem !important;
}

.mr-80 {
  margin-right: 25rem !important;
}

.pr-80 {
  padding-right: 25rem !important;
}

.mb-80 {
  margin-bottom: 25rem !important;
}

.pb-80 {
  padding-bottom: 25rem !important;
}

.ml-80 {
  margin-left: 25rem !important;
}

.pl-80 {
  padding-left: 25rem !important;
}

.m-80 {
  margin: 25rem !important;
}

.p-80 {
  padding: 25rem !important;
}

.my-80 {
  margin-top: 25rem !important;
  margin-bottom: 25rem !important;
}

.py-80 {
  padding-top: 25rem !important;
  padding-bottom: 25rem !important;
}

.mx-80 {
  margin-left: 25rem !important;
  margin-right: 25rem !important;
}

.px-80 {
  padding-left: 25rem !important;
  padding-right: 25rem !important;
}

.mt-81 {
  margin-top: 25.3125rem !important;
}

.pt-81 {
  padding-top: 25.3125rem !important;
}

.mr-81 {
  margin-right: 25.3125rem !important;
}

.pr-81 {
  padding-right: 25.3125rem !important;
}

.mb-81 {
  margin-bottom: 25.3125rem !important;
}

.pb-81 {
  padding-bottom: 25.3125rem !important;
}

.ml-81 {
  margin-left: 25.3125rem !important;
}

.pl-81 {
  padding-left: 25.3125rem !important;
}

.m-81 {
  margin: 25.3125rem !important;
}

.p-81 {
  padding: 25.3125rem !important;
}

.my-81 {
  margin-top: 25.3125rem !important;
  margin-bottom: 25.3125rem !important;
}

.py-81 {
  padding-top: 25.3125rem !important;
  padding-bottom: 25.3125rem !important;
}

.mx-81 {
  margin-left: 25.3125rem !important;
  margin-right: 25.3125rem !important;
}

.px-81 {
  padding-left: 25.3125rem !important;
  padding-right: 25.3125rem !important;
}

.mt-82 {
  margin-top: 25.625rem !important;
}

.pt-82 {
  padding-top: 25.625rem !important;
}

.mr-82 {
  margin-right: 25.625rem !important;
}

.pr-82 {
  padding-right: 25.625rem !important;
}

.mb-82 {
  margin-bottom: 25.625rem !important;
}

.pb-82 {
  padding-bottom: 25.625rem !important;
}

.ml-82 {
  margin-left: 25.625rem !important;
}

.pl-82 {
  padding-left: 25.625rem !important;
}

.m-82 {
  margin: 25.625rem !important;
}

.p-82 {
  padding: 25.625rem !important;
}

.my-82 {
  margin-top: 25.625rem !important;
  margin-bottom: 25.625rem !important;
}

.py-82 {
  padding-top: 25.625rem !important;
  padding-bottom: 25.625rem !important;
}

.mx-82 {
  margin-left: 25.625rem !important;
  margin-right: 25.625rem !important;
}

.px-82 {
  padding-left: 25.625rem !important;
  padding-right: 25.625rem !important;
}

.mt-83 {
  margin-top: 25.9375rem !important;
}

.pt-83 {
  padding-top: 25.9375rem !important;
}

.mr-83 {
  margin-right: 25.9375rem !important;
}

.pr-83 {
  padding-right: 25.9375rem !important;
}

.mb-83 {
  margin-bottom: 25.9375rem !important;
}

.pb-83 {
  padding-bottom: 25.9375rem !important;
}

.ml-83 {
  margin-left: 25.9375rem !important;
}

.pl-83 {
  padding-left: 25.9375rem !important;
}

.m-83 {
  margin: 25.9375rem !important;
}

.p-83 {
  padding: 25.9375rem !important;
}

.my-83 {
  margin-top: 25.9375rem !important;
  margin-bottom: 25.9375rem !important;
}

.py-83 {
  padding-top: 25.9375rem !important;
  padding-bottom: 25.9375rem !important;
}

.mx-83 {
  margin-left: 25.9375rem !important;
  margin-right: 25.9375rem !important;
}

.px-83 {
  padding-left: 25.9375rem !important;
  padding-right: 25.9375rem !important;
}

.mt-84 {
  margin-top: 26.25rem !important;
}

.pt-84 {
  padding-top: 26.25rem !important;
}

.mr-84 {
  margin-right: 26.25rem !important;
}

.pr-84 {
  padding-right: 26.25rem !important;
}

.mb-84 {
  margin-bottom: 26.25rem !important;
}

.pb-84 {
  padding-bottom: 26.25rem !important;
}

.ml-84 {
  margin-left: 26.25rem !important;
}

.pl-84 {
  padding-left: 26.25rem !important;
}

.m-84 {
  margin: 26.25rem !important;
}

.p-84 {
  padding: 26.25rem !important;
}

.my-84 {
  margin-top: 26.25rem !important;
  margin-bottom: 26.25rem !important;
}

.py-84 {
  padding-top: 26.25rem !important;
  padding-bottom: 26.25rem !important;
}

.mx-84 {
  margin-left: 26.25rem !important;
  margin-right: 26.25rem !important;
}

.px-84 {
  padding-left: 26.25rem !important;
  padding-right: 26.25rem !important;
}

.mt-85 {
  margin-top: 26.5625rem !important;
}

.pt-85 {
  padding-top: 26.5625rem !important;
}

.mr-85 {
  margin-right: 26.5625rem !important;
}

.pr-85 {
  padding-right: 26.5625rem !important;
}

.mb-85 {
  margin-bottom: 26.5625rem !important;
}

.pb-85 {
  padding-bottom: 26.5625rem !important;
}

.ml-85 {
  margin-left: 26.5625rem !important;
}

.pl-85 {
  padding-left: 26.5625rem !important;
}

.m-85 {
  margin: 26.5625rem !important;
}

.p-85 {
  padding: 26.5625rem !important;
}

.my-85 {
  margin-top: 26.5625rem !important;
  margin-bottom: 26.5625rem !important;
}

.py-85 {
  padding-top: 26.5625rem !important;
  padding-bottom: 26.5625rem !important;
}

.mx-85 {
  margin-left: 26.5625rem !important;
  margin-right: 26.5625rem !important;
}

.px-85 {
  padding-left: 26.5625rem !important;
  padding-right: 26.5625rem !important;
}

.mt-86 {
  margin-top: 26.875rem !important;
}

.pt-86 {
  padding-top: 26.875rem !important;
}

.mr-86 {
  margin-right: 26.875rem !important;
}

.pr-86 {
  padding-right: 26.875rem !important;
}

.mb-86 {
  margin-bottom: 26.875rem !important;
}

.pb-86 {
  padding-bottom: 26.875rem !important;
}

.ml-86 {
  margin-left: 26.875rem !important;
}

.pl-86 {
  padding-left: 26.875rem !important;
}

.m-86 {
  margin: 26.875rem !important;
}

.p-86 {
  padding: 26.875rem !important;
}

.my-86 {
  margin-top: 26.875rem !important;
  margin-bottom: 26.875rem !important;
}

.py-86 {
  padding-top: 26.875rem !important;
  padding-bottom: 26.875rem !important;
}

.mx-86 {
  margin-left: 26.875rem !important;
  margin-right: 26.875rem !important;
}

.px-86 {
  padding-left: 26.875rem !important;
  padding-right: 26.875rem !important;
}

.mt-87 {
  margin-top: 27.1875rem !important;
}

.pt-87 {
  padding-top: 27.1875rem !important;
}

.mr-87 {
  margin-right: 27.1875rem !important;
}

.pr-87 {
  padding-right: 27.1875rem !important;
}

.mb-87 {
  margin-bottom: 27.1875rem !important;
}

.pb-87 {
  padding-bottom: 27.1875rem !important;
}

.ml-87 {
  margin-left: 27.1875rem !important;
}

.pl-87 {
  padding-left: 27.1875rem !important;
}

.m-87 {
  margin: 27.1875rem !important;
}

.p-87 {
  padding: 27.1875rem !important;
}

.my-87 {
  margin-top: 27.1875rem !important;
  margin-bottom: 27.1875rem !important;
}

.py-87 {
  padding-top: 27.1875rem !important;
  padding-bottom: 27.1875rem !important;
}

.mx-87 {
  margin-left: 27.1875rem !important;
  margin-right: 27.1875rem !important;
}

.px-87 {
  padding-left: 27.1875rem !important;
  padding-right: 27.1875rem !important;
}

.mt-88 {
  margin-top: 27.5rem !important;
}

.pt-88 {
  padding-top: 27.5rem !important;
}

.mr-88 {
  margin-right: 27.5rem !important;
}

.pr-88 {
  padding-right: 27.5rem !important;
}

.mb-88 {
  margin-bottom: 27.5rem !important;
}

.pb-88 {
  padding-bottom: 27.5rem !important;
}

.ml-88 {
  margin-left: 27.5rem !important;
}

.pl-88 {
  padding-left: 27.5rem !important;
}

.m-88 {
  margin: 27.5rem !important;
}

.p-88 {
  padding: 27.5rem !important;
}

.my-88 {
  margin-top: 27.5rem !important;
  margin-bottom: 27.5rem !important;
}

.py-88 {
  padding-top: 27.5rem !important;
  padding-bottom: 27.5rem !important;
}

.mx-88 {
  margin-left: 27.5rem !important;
  margin-right: 27.5rem !important;
}

.px-88 {
  padding-left: 27.5rem !important;
  padding-right: 27.5rem !important;
}

.mt-89 {
  margin-top: 27.8125rem !important;
}

.pt-89 {
  padding-top: 27.8125rem !important;
}

.mr-89 {
  margin-right: 27.8125rem !important;
}

.pr-89 {
  padding-right: 27.8125rem !important;
}

.mb-89 {
  margin-bottom: 27.8125rem !important;
}

.pb-89 {
  padding-bottom: 27.8125rem !important;
}

.ml-89 {
  margin-left: 27.8125rem !important;
}

.pl-89 {
  padding-left: 27.8125rem !important;
}

.m-89 {
  margin: 27.8125rem !important;
}

.p-89 {
  padding: 27.8125rem !important;
}

.my-89 {
  margin-top: 27.8125rem !important;
  margin-bottom: 27.8125rem !important;
}

.py-89 {
  padding-top: 27.8125rem !important;
  padding-bottom: 27.8125rem !important;
}

.mx-89 {
  margin-left: 27.8125rem !important;
  margin-right: 27.8125rem !important;
}

.px-89 {
  padding-left: 27.8125rem !important;
  padding-right: 27.8125rem !important;
}

.mt-90 {
  margin-top: 28.125rem !important;
}

.pt-90 {
  padding-top: 28.125rem !important;
}

.mr-90 {
  margin-right: 28.125rem !important;
}

.pr-90 {
  padding-right: 28.125rem !important;
}

.mb-90 {
  margin-bottom: 28.125rem !important;
}

.pb-90 {
  padding-bottom: 28.125rem !important;
}

.ml-90 {
  margin-left: 28.125rem !important;
}

.pl-90 {
  padding-left: 28.125rem !important;
}

.m-90 {
  margin: 28.125rem !important;
}

.p-90 {
  padding: 28.125rem !important;
}

.my-90 {
  margin-top: 28.125rem !important;
  margin-bottom: 28.125rem !important;
}

.py-90 {
  padding-top: 28.125rem !important;
  padding-bottom: 28.125rem !important;
}

.mx-90 {
  margin-left: 28.125rem !important;
  margin-right: 28.125rem !important;
}

.px-90 {
  padding-left: 28.125rem !important;
  padding-right: 28.125rem !important;
}

.mt-91 {
  margin-top: 28.4375rem !important;
}

.pt-91 {
  padding-top: 28.4375rem !important;
}

.mr-91 {
  margin-right: 28.4375rem !important;
}

.pr-91 {
  padding-right: 28.4375rem !important;
}

.mb-91 {
  margin-bottom: 28.4375rem !important;
}

.pb-91 {
  padding-bottom: 28.4375rem !important;
}

.ml-91 {
  margin-left: 28.4375rem !important;
}

.pl-91 {
  padding-left: 28.4375rem !important;
}

.m-91 {
  margin: 28.4375rem !important;
}

.p-91 {
  padding: 28.4375rem !important;
}

.my-91 {
  margin-top: 28.4375rem !important;
  margin-bottom: 28.4375rem !important;
}

.py-91 {
  padding-top: 28.4375rem !important;
  padding-bottom: 28.4375rem !important;
}

.mx-91 {
  margin-left: 28.4375rem !important;
  margin-right: 28.4375rem !important;
}

.px-91 {
  padding-left: 28.4375rem !important;
  padding-right: 28.4375rem !important;
}

.mt-92 {
  margin-top: 28.75rem !important;
}

.pt-92 {
  padding-top: 28.75rem !important;
}

.mr-92 {
  margin-right: 28.75rem !important;
}

.pr-92 {
  padding-right: 28.75rem !important;
}

.mb-92 {
  margin-bottom: 28.75rem !important;
}

.pb-92 {
  padding-bottom: 28.75rem !important;
}

.ml-92 {
  margin-left: 28.75rem !important;
}

.pl-92 {
  padding-left: 28.75rem !important;
}

.m-92 {
  margin: 28.75rem !important;
}

.p-92 {
  padding: 28.75rem !important;
}

.my-92 {
  margin-top: 28.75rem !important;
  margin-bottom: 28.75rem !important;
}

.py-92 {
  padding-top: 28.75rem !important;
  padding-bottom: 28.75rem !important;
}

.mx-92 {
  margin-left: 28.75rem !important;
  margin-right: 28.75rem !important;
}

.px-92 {
  padding-left: 28.75rem !important;
  padding-right: 28.75rem !important;
}

.mt-93 {
  margin-top: 29.0625rem !important;
}

.pt-93 {
  padding-top: 29.0625rem !important;
}

.mr-93 {
  margin-right: 29.0625rem !important;
}

.pr-93 {
  padding-right: 29.0625rem !important;
}

.mb-93 {
  margin-bottom: 29.0625rem !important;
}

.pb-93 {
  padding-bottom: 29.0625rem !important;
}

.ml-93 {
  margin-left: 29.0625rem !important;
}

.pl-93 {
  padding-left: 29.0625rem !important;
}

.m-93 {
  margin: 29.0625rem !important;
}

.p-93 {
  padding: 29.0625rem !important;
}

.my-93 {
  margin-top: 29.0625rem !important;
  margin-bottom: 29.0625rem !important;
}

.py-93 {
  padding-top: 29.0625rem !important;
  padding-bottom: 29.0625rem !important;
}

.mx-93 {
  margin-left: 29.0625rem !important;
  margin-right: 29.0625rem !important;
}

.px-93 {
  padding-left: 29.0625rem !important;
  padding-right: 29.0625rem !important;
}

.mt-94 {
  margin-top: 29.375rem !important;
}

.pt-94 {
  padding-top: 29.375rem !important;
}

.mr-94 {
  margin-right: 29.375rem !important;
}

.pr-94 {
  padding-right: 29.375rem !important;
}

.mb-94 {
  margin-bottom: 29.375rem !important;
}

.pb-94 {
  padding-bottom: 29.375rem !important;
}

.ml-94 {
  margin-left: 29.375rem !important;
}

.pl-94 {
  padding-left: 29.375rem !important;
}

.m-94 {
  margin: 29.375rem !important;
}

.p-94 {
  padding: 29.375rem !important;
}

.my-94 {
  margin-top: 29.375rem !important;
  margin-bottom: 29.375rem !important;
}

.py-94 {
  padding-top: 29.375rem !important;
  padding-bottom: 29.375rem !important;
}

.mx-94 {
  margin-left: 29.375rem !important;
  margin-right: 29.375rem !important;
}

.px-94 {
  padding-left: 29.375rem !important;
  padding-right: 29.375rem !important;
}

.mt-95 {
  margin-top: 29.6875rem !important;
}

.pt-95 {
  padding-top: 29.6875rem !important;
}

.mr-95 {
  margin-right: 29.6875rem !important;
}

.pr-95 {
  padding-right: 29.6875rem !important;
}

.mb-95 {
  margin-bottom: 29.6875rem !important;
}

.pb-95 {
  padding-bottom: 29.6875rem !important;
}

.ml-95 {
  margin-left: 29.6875rem !important;
}

.pl-95 {
  padding-left: 29.6875rem !important;
}

.m-95 {
  margin: 29.6875rem !important;
}

.p-95 {
  padding: 29.6875rem !important;
}

.my-95 {
  margin-top: 29.6875rem !important;
  margin-bottom: 29.6875rem !important;
}

.py-95 {
  padding-top: 29.6875rem !important;
  padding-bottom: 29.6875rem !important;
}

.mx-95 {
  margin-left: 29.6875rem !important;
  margin-right: 29.6875rem !important;
}

.px-95 {
  padding-left: 29.6875rem !important;
  padding-right: 29.6875rem !important;
}

.mt-96 {
  margin-top: 30rem !important;
}

.pt-96 {
  padding-top: 30rem !important;
}

.mr-96 {
  margin-right: 30rem !important;
}

.pr-96 {
  padding-right: 30rem !important;
}

.mb-96 {
  margin-bottom: 30rem !important;
}

.pb-96 {
  padding-bottom: 30rem !important;
}

.ml-96 {
  margin-left: 30rem !important;
}

.pl-96 {
  padding-left: 30rem !important;
}

.m-96 {
  margin: 30rem !important;
}

.p-96 {
  padding: 30rem !important;
}

.my-96 {
  margin-top: 30rem !important;
  margin-bottom: 30rem !important;
}

.py-96 {
  padding-top: 30rem !important;
  padding-bottom: 30rem !important;
}

.mx-96 {
  margin-left: 30rem !important;
  margin-right: 30rem !important;
}

.px-96 {
  padding-left: 30rem !important;
  padding-right: 30rem !important;
}

.mt-97 {
  margin-top: 30.3125rem !important;
}

.pt-97 {
  padding-top: 30.3125rem !important;
}

.mr-97 {
  margin-right: 30.3125rem !important;
}

.pr-97 {
  padding-right: 30.3125rem !important;
}

.mb-97 {
  margin-bottom: 30.3125rem !important;
}

.pb-97 {
  padding-bottom: 30.3125rem !important;
}

.ml-97 {
  margin-left: 30.3125rem !important;
}

.pl-97 {
  padding-left: 30.3125rem !important;
}

.m-97 {
  margin: 30.3125rem !important;
}

.p-97 {
  padding: 30.3125rem !important;
}

.my-97 {
  margin-top: 30.3125rem !important;
  margin-bottom: 30.3125rem !important;
}

.py-97 {
  padding-top: 30.3125rem !important;
  padding-bottom: 30.3125rem !important;
}

.mx-97 {
  margin-left: 30.3125rem !important;
  margin-right: 30.3125rem !important;
}

.px-97 {
  padding-left: 30.3125rem !important;
  padding-right: 30.3125rem !important;
}

.mt-98 {
  margin-top: 30.625rem !important;
}

.pt-98 {
  padding-top: 30.625rem !important;
}

.mr-98 {
  margin-right: 30.625rem !important;
}

.pr-98 {
  padding-right: 30.625rem !important;
}

.mb-98 {
  margin-bottom: 30.625rem !important;
}

.pb-98 {
  padding-bottom: 30.625rem !important;
}

.ml-98 {
  margin-left: 30.625rem !important;
}

.pl-98 {
  padding-left: 30.625rem !important;
}

.m-98 {
  margin: 30.625rem !important;
}

.p-98 {
  padding: 30.625rem !important;
}

.my-98 {
  margin-top: 30.625rem !important;
  margin-bottom: 30.625rem !important;
}

.py-98 {
  padding-top: 30.625rem !important;
  padding-bottom: 30.625rem !important;
}

.mx-98 {
  margin-left: 30.625rem !important;
  margin-right: 30.625rem !important;
}

.px-98 {
  padding-left: 30.625rem !important;
  padding-right: 30.625rem !important;
}

.mt-99 {
  margin-top: 30.9375rem !important;
}

.pt-99 {
  padding-top: 30.9375rem !important;
}

.mr-99 {
  margin-right: 30.9375rem !important;
}

.pr-99 {
  padding-right: 30.9375rem !important;
}

.mb-99 {
  margin-bottom: 30.9375rem !important;
}

.pb-99 {
  padding-bottom: 30.9375rem !important;
}

.ml-99 {
  margin-left: 30.9375rem !important;
}

.pl-99 {
  padding-left: 30.9375rem !important;
}

.m-99 {
  margin: 30.9375rem !important;
}

.p-99 {
  padding: 30.9375rem !important;
}

.my-99 {
  margin-top: 30.9375rem !important;
  margin-bottom: 30.9375rem !important;
}

.py-99 {
  padding-top: 30.9375rem !important;
  padding-bottom: 30.9375rem !important;
}

.mx-99 {
  margin-left: 30.9375rem !important;
  margin-right: 30.9375rem !important;
}

.px-99 {
  padding-left: 30.9375rem !important;
  padding-right: 30.9375rem !important;
}

.mt-100 {
  margin-top: 31.25rem !important;
}

.pt-100 {
  padding-top: 31.25rem !important;
}

.mr-100 {
  margin-right: 31.25rem !important;
}

.pr-100 {
  padding-right: 31.25rem !important;
}

.mb-100 {
  margin-bottom: 31.25rem !important;
}

.pb-100 {
  padding-bottom: 31.25rem !important;
}

.ml-100 {
  margin-left: 31.25rem !important;
}

.pl-100 {
  padding-left: 31.25rem !important;
}

.m-100 {
  margin: 31.25rem !important;
}

.p-100 {
  padding: 31.25rem !important;
}

.my-100 {
  margin-top: 31.25rem !important;
  margin-bottom: 31.25rem !important;
}

.py-100 {
  padding-top: 31.25rem !important;
  padding-bottom: 31.25rem !important;
}

.mx-100 {
  margin-left: 31.25rem !important;
  margin-right: 31.25rem !important;
}

.px-100 {
  padding-left: 31.25rem !important;
  padding-right: 31.25rem !important;
}

.h-0 {
  height: 0% !important;
}

.w-0 {
  width: 0% !important;
}

.h-10 {
  height: 10% !important;
}

.w-10 {
  width: 10% !important;
}

.h-20 {
  height: 20% !important;
}

.w-20 {
  width: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.w-30 {
  width: 30% !important;
}

.h-40 {
  height: 40% !important;
}

.w-40 {
  width: 40% !important;
}

.h-50 {
  height: 50% !important;
}

.w-50 {
  width: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.w-60 {
  width: 60% !important;
}

.h-70 {
  height: 70% !important;
}

.w-70 {
  width: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.w-80 {
  width: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.w-90 {
  width: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

@font-face {
  font-family: socicon;
  src: url("../fonts/socicon.eot");
  src: url("../fonts/socicon.eot?#iefix") format("embedded-opentype"), url("../fonts/socicon.woff") format("woff"), url("../fonts/socicon.ttf") format("truetype"), url("../fonts/socicon.svg#sociconregular") format("svg");
  font-weight: normal;
  font-style: normal;
  text-transform: initial;
}
.socicon::before,
[class^=socicon-]::before,
[class*=" socicon-"]::before {
  display: inline-block;
  font-family: socicon;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.socicon-500px::before {
  content: "\e000";
}

.socicon-8tracks::before {
  content: "\e001";
}

.socicon-airbnb::before {
  content: "\e002";
}

.socicon-alliance::before {
  content: "\e003";
}

.socicon-amazon::before {
  content: "\e004";
}

.socicon-amplement::before {
  content: "\e005";
}

.socicon-android::before {
  content: "\e006";
}

.socicon-angellist::before {
  content: "\e007";
}

.socicon-apple::before {
  content: "\e008";
}

.socicon-appnet::before {
  content: "\e009";
}

.socicon-baidu::before {
  content: "\e00a";
}

.socicon-bandcamp::before {
  content: "\e00b";
}

.socicon-battlenet::before {
  content: "\e00c";
}

.socicon-beam::before {
  content: "\e00d";
}

.socicon-bebee::before {
  content: "\e00e";
}

.socicon-bebo::before {
  content: "\e00f";
}

.socicon-behance::before {
  content: "\e010";
}

.socicon-blizzard::before {
  content: "\e011";
}

.socicon-blogger::before {
  content: "\e012";
}

.socicon-buffer::before {
  content: "\e013";
}

.socicon-chrome::before {
  content: "\e014";
}

.socicon-coderwall::before {
  content: "\e015";
}

.socicon-curse::before {
  content: "\e016";
}

.socicon-dailymotion::before {
  content: "\e017";
}

.socicon-deezer::before {
  content: "\e018";
}

.socicon-delicious::before {
  content: "\e019";
}

.socicon-deviantart::before {
  content: "\e01a";
}

.socicon-diablo::before {
  content: "\e01b";
}

.socicon-digg::before {
  content: "\e01c";
}

.socicon-discord::before {
  content: "\e01d";
}

.socicon-disqus::before {
  content: "\e01e";
}

.socicon-douban::before {
  content: "\e01f";
}

.socicon-draugiem::before {
  content: "\e020";
}

.socicon-dribbble::before {
  content: "\e021";
}

.socicon-drupal::before {
  content: "\e022";
}

.socicon-ebay::before {
  content: "\e023";
}

.socicon-ello::before {
  content: "\e024";
}

.socicon-endomodo::before {
  content: "\e025";
}

.socicon-envato::before {
  content: "\e026";
}

.socicon-etsy::before {
  content: "\e027";
}

.socicon-facebook::before {
  content: "\e028";
}

.socicon-feedburner::before {
  content: "\e029";
}

.socicon-filmweb::before {
  content: "\e02a";
}

.socicon-firefox::before {
  content: "\e02b";
}

.socicon-flattr::before {
  content: "\e02c";
}

.socicon-flickr::before {
  content: "\e02d";
}

.socicon-formulr::before {
  content: "\e02e";
}

.socicon-forrst::before {
  content: "\e02f";
}

.socicon-foursquare::before {
  content: "\e030";
}

.socicon-friendfeed::before {
  content: "\e031";
}

.socicon-github::before {
  content: "\e032";
}

.socicon-goodreads::before {
  content: "\e033";
}

.socicon-google::before {
  content: "\e034";
}

.socicon-google-scholar::before {
  content: "\e035";
}

.socicon-googlegroups::before {
  content: "\e036";
}

.socicon-googlephotos::before {
  content: "\e037";
}

.socicon-googleplus::before {
  content: "\e038";
}

.socicon-grooveshark::before {
  content: "\e039";
}

.socicon-hackerrank::before {
  content: "\e03a";
}

.socicon-hearthstone::before {
  content: "\e03b";
}

.socicon-hellocoton::before {
  content: "\e03c";
}

.socicon-heroes::before {
  content: "\e03d";
}

.socicon-hitbox::before {
  content: "\e03e";
}

.socicon-horde::before {
  content: "\e03f";
}

.socicon-houzz::before {
  content: "\e040";
}

.socicon-icq::before {
  content: "\e041";
}

.socicon-identica::before {
  content: "\e042";
}

.socicon-imdb::before {
  content: "\e043";
}

.socicon-instagram::before {
  content: "\e044";
}

.socicon-issuu::before {
  content: "\e045";
}

.socicon-istock::before {
  content: "\e046";
}

.socicon-itunes::before {
  content: "\e047";
}

.socicon-keybase::before {
  content: "\e048";
}

.socicon-lanyrd::before {
  content: "\e049";
}

.socicon-lastfm::before {
  content: "\e04a";
}

.socicon-line::before {
  content: "\e04b";
}

.socicon-linkedin::before {
  content: "\e04c";
}

.socicon-livejournal::before {
  content: "\e04d";
}

.socicon-lyft::before {
  content: "\e04e";
}

.socicon-macos::before {
  content: "\e04f";
}

.socicon-mail::before {
  content: "\e050";
}

.socicon-medium::before {
  content: "\e051";
}

.socicon-meetup::before {
  content: "\e052";
}

.socicon-mixcloud::before {
  content: "\e053";
}

.socicon-modelmayhem::before {
  content: "\e054";
}

.socicon-mumble::before {
  content: "\e055";
}

.socicon-myspace::before {
  content: "\e056";
}

.socicon-newsvine::before {
  content: "\e057";
}

.socicon-nintendo::before {
  content: "\e058";
}

.socicon-npm::before {
  content: "\e059";
}

.socicon-odnoklassniki::before {
  content: "\e05a";
}

.socicon-openid::before {
  content: "\e05b";
}

.socicon-opera::before {
  content: "\e05c";
}

.socicon-outlook::before {
  content: "\e05d";
}

.socicon-overwatch::before {
  content: "\e05e";
}

.socicon-patreon::before {
  content: "\e05f";
}

.socicon-paypal::before {
  content: "\e060";
}

.socicon-periscope::before {
  content: "\e061";
}

.socicon-persona::before {
  content: "\e062";
}

.socicon-pinterest::before {
  content: "\e063";
}

.socicon-play::before {
  content: "\e064";
}

.socicon-player::before {
  content: "\e065";
}

.socicon-playstation::before {
  content: "\e066";
}

.socicon-pocket::before {
  content: "\e067";
}

.socicon-qq::before {
  content: "\e068";
}

.socicon-quora::before {
  content: "\e069";
}

.socicon-raidcall::before {
  content: "\e06a";
}

.socicon-ravelry::before {
  content: "\e06b";
}

.socicon-reddit::before {
  content: "\e06c";
}

.socicon-renren::before {
  content: "\e06d";
}

.socicon-researchgate::before {
  content: "\e06e";
}

.socicon-residentadvisor::before {
  content: "\e06f";
}

.socicon-reverbnation::before {
  content: "\e070";
}

.socicon-rss::before {
  content: "\e071";
}

.socicon-sharethis::before {
  content: "\e072";
}

.socicon-skype::before {
  content: "\e073";
}

.socicon-slideshare::before {
  content: "\e074";
}

.socicon-smugmug::before {
  content: "\e075";
}

.socicon-snapchat::before {
  content: "\e076";
}

.socicon-songkick::before {
  content: "\e077";
}

.socicon-soundcloud::before {
  content: "\e078";
}

.socicon-spotify::before {
  content: "\e079";
}

.socicon-stackexchange::before {
  content: "\e07a";
}

.socicon-stackoverflow::before {
  content: "\e07b";
}

.socicon-starcraft::before {
  content: "\e07c";
}

.socicon-stayfriends::before {
  content: "\e07d";
}

.socicon-steam::before {
  content: "\e07e";
}

.socicon-storehouse::before {
  content: "\e07f";
}

.socicon-strava::before {
  content: "\e080";
}

.socicon-streamjar::before {
  content: "\e081";
}

.socicon-stumbleupon::before {
  content: "\e082";
}

.socicon-swarm::before {
  content: "\e083";
}

.socicon-teamspeak::before {
  content: "\e084";
}

.socicon-teamviewer::before {
  content: "\e085";
}

.socicon-technorati::before {
  content: "\e086";
}

.socicon-telegram::before {
  content: "\e087";
}

.socicon-tripadvisor::before {
  content: "\e088";
}

.socicon-tripit::before {
  content: "\e089";
}

.socicon-triplej::before {
  content: "\e08a";
}

.socicon-tumblr::before {
  content: "\e08b";
}

.socicon-twitch::before {
  content: "\e08c";
}

.socicon-twitter::before {
  content: "\e08d";
}

.socicon-uber::before {
  content: "\e08e";
}

.socicon-ventrilo::before {
  content: "\e08f";
}

.socicon-viadeo::before {
  content: "\e090";
}

.socicon-viber::before {
  content: "\e091";
}

.socicon-viewbug::before {
  content: "\e092";
}

.socicon-vimeo::before {
  content: "\e093";
}

.socicon-vine::before {
  content: "\e094";
}

.socicon-vkontakte::before {
  content: "\e095";
}

.socicon-warcraft::before {
  content: "\e096";
}

.socicon-wechat::before {
  content: "\e097";
}

.socicon-weibo::before {
  content: "\e098";
}

.socicon-whatsapp::before {
  content: "\e099";
}

.socicon-wikipedia::before {
  content: "\e09a";
}

.socicon-windows::before {
  content: "\e09b";
}

.socicon-wordpress::before {
  content: "\e09c";
}

.socicon-wykop::before {
  content: "\e09d";
}

.socicon-xbox::before {
  content: "\e09e";
}

.socicon-xing::before {
  content: "\e09f";
}

.socicon-yahoo::before {
  content: "\e0a0";
}

.socicon-yammer::before {
  content: "\e0a1";
}

.socicon-yandex::before {
  content: "\e0a2";
}

.socicon-yelp::before {
  content: "\e0a3";
}

.socicon-younow::before {
  content: "\e0a4";
}

.socicon-youtube::before {
  content: "\e0a5";
}

.socicon-zapier::before {
  content: "\e0a6";
}

.socicon-zerply::before {
  content: "\e0a7";
}

.socicon-zomato::before {
  content: "\e0a8";
}

.socicon-zynga::before {
  content: "\e0a9";
}

html.is-animating .transition-fade,
body.is-animating .transition-fade {
  opacity: 0;
}
html .transition-fade,
body .transition-fade {
  transition: 0.4s;
  opacity: 1;
}

html {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}
@media (width <= 1680px) {
  html {
    font-size: 13.5px;
  }
}
@media (width <= 1440px) {
  html {
    font-size: 13px;
  }
}
@media (width <= 1280px) {
  html {
    font-size: 12.5px;
  }
}
@media (width <= 990px) {
  html {
    font-size: 2vw;
  }
}
@media (width < 768px) {
  html {
    font-size: 3vw;
  }
}
html h1,
html h2,
html h3,
html h4,
html h5,
html h6 {
  font-weight: 400;
  margin: 0;
  padding: 0;
}
html h1,
html .h1 {
  font-family: "Poppins Medium", sans-serif;
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 1.45;
}
html h2,
html .h2 {
  font-family: "Poppins Medium", sans-serif;
  font-weight: 200;
  font-size: 2.625rem;
  line-height: 1.3;
}
@media (width < 768px) {
  html h2,
  html .h2 {
    font-size: 2.2rem;
  }
}
html h3,
html .h3 {
  font-family: "Poppins Medium", sans-serif;
  font-size: 2.25rem;
  line-height: 1.2;
}
html h4,
html .h4 {
  font-size: 2rem;
}
html h5,
html .h5 {
  font-family: "Poppins Medium", sans-serif;
  font-size: 2.25rem;
  line-height: 1.2;
}
html h6,
html .h6 {
  font-family: "Poppins Light", sans-serif;
  font-size: 1.625rem;
}
html h6.md,
html .h6.md {
  font-size: 1.75rem;
}
html p,
html .paragraph {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  letter-spacing: 0.025rem;
  margin: 0.5rem 0;
}
@media (width <= 990px) {
  html p,
  html .paragraph {
    font-size: 1.375rem;
  }
}
html p.xs,
html .paragraph.xs {
  font-size: 0.9375rem;
}
@media (width <= 990px) {
  html p.xs,
  html .paragraph.xs {
    font-size: 1.125rem;
  }
}
html a {
  font-size: 1rem;
  text-decoration: none;
}
html a.nav-entry {
  font-size: 0.875rem;
}
@media (width < 768px) {
  html br {
    content: "";
  }
  html br::before {
    content: " ";
  }
}

ul li {
  font-family: "Poppins Light", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.025rem;
}
@media (width <= 990px) {
  ul li {
    font-size: 1.0625rem;
  }
}
@media (width < 768px) {
  ul li {
    font-size: 1.25rem;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.fullscreen-img {
  animation: fadeIn 0.1s ease-in-out;
  background-size: contain;
  cursor: zoom-out;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.company-header {
  position: relative;
  margin: -1rem 0 0;
}
.company-header .blob-wrapper {
  position: absolute;
  z-index: 0;
  top: -36%;
  left: -8%;
  width: 65%;
}
@media (width <= 1280px) {
  .company-header .blob-wrapper {
    transform: scale(1.15);
    transform-origin: center;
  }
}
@media (width <= 990px) {
  .company-header .blob-wrapper {
    top: auto;
    bottom: -3%;
    left: -10%;
    width: 125%;
  }
}
@media (width < 768px) {
  .company-header .blob-wrapper {
    top: auto;
    bottom: 2%;
    left: -25%;
    width: 160%;
  }
}
.company-header .blob-wrapper svg {
  animation: none;
}
.company-header .flower-lottie {
  display: block;
  position: absolute;
  left: 0;
  width: 25%;
  top: 30%;
  transform: rotate(195deg);
}
@media (width <= 990px) {
  .company-header .flower-lottie {
    width: 35%;
    top: 35%;
  }
}
@media (width < 768px) {
  .company-header .flower-lottie {
    width: 45%;
    top: 33%;
  }
}
.company-header .content {
  align-items: center;
  margin: 0 0 2.5rem;
}
@media (width <= 990px) {
  .company-header .content .blob-mask-3 {
    order: -1;
  }
}
@media (width < 768px) {
  .company-header .content .column {
    padding: 0;
  }
}
.company-header .content .text {
  padding: 0 5% 0 0;
}
@media (width <= 990px) {
  .company-header .content .text {
    text-align: center;
    width: 75%;
    margin: 2.5rem auto 10rem;
    padding: 0;
  }
}
@media (width < 768px) {
  .company-header .content .text {
    width: 100%;
  }
}
.company-header .content .text p {
  margin: 2.5rem 0 0;
}
.company-header .content .image img {
  aspect-ratio: 1.15;
}
.company-header .sections-navbar {
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: center;
}
.company-header .sections-navbar .company-submenu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 2rem;
}
@media (width <= 990px) {
  .company-header .sections-navbar .company-submenu {
    gap: 0 1rem;
  }
}
@media (width < 768px) {
  .company-header .sections-navbar .company-submenu {
    justify-content: flex-start;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .company-header .sections-navbar .company-submenu::-webkit-scrollbar {
    display: none;
  }
}
.company-header .sections-navbar .company-submenu .btn {
  flex: 0 0 auto;
}
@media (width < 768px) {
  .company-header .sections-navbar .company-submenu .btn {
    min-width: 11rem;
    text-align: center;
  }
}
.company-header .sections-navbar .company-submenu .btn.active {
  background-color: #ffb000;
  color: #414141;
}
.company-header .sections-navbar.sticky .company-submenu {
  position: fixed;
  padding: 1rem 0;
  background-color: #efede6;
  left: 2rem;
  width: calc(100vw - 4rem);
  border-radius: var(--br-default) var(--br-default) 0 0;
}
@media (width <= 990px) {
  .company-header .sections-navbar.sticky .company-submenu {
    left: 1rem;
    width: calc(100vw - 2rem);
  }
}
@media (width < 768px) {
  .company-header .sections-navbar.sticky .company-submenu {
    padding: 1rem;
  }
}

.azienda .biologico,
.azienda .nomadismo,
.azienda .mission,
.azienda .metodo {
  animation: fadeIn 0.8s ease-out;
}

.azienda .biologico,
.azienda .mission,
.azienda .metodo {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.azienda .double-column .content .column .image img {
  aspect-ratio: 1;
}

.azienda .triple-column .content .column .image img {
  aspect-ratio: 1;
}
@media (width <= 990px) {
  .azienda .triple-column .content .column {
    margin: 0 0 1rem;
  }
}

.bee-info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.bee-info .blob-wrapper {
  position: absolute;
  z-index: 0;
  right: -15%;
  width: 70%;
  top: 60%;
}
.bee-info .step p {
  margin: 0.5rem 0 1rem;
}
.bee-info .step p br {
  display: block;
}

.product-header {
  position: relative;
  aspect-ratio: 1856/1080;
}
.product-header .left-lottie {
  position: absolute;
  z-index: 1;
  left: 5%;
  top: 0;
  width: 18%;
}
@media (width <= 990px) {
  .product-header .left-lottie {
    left: 0;
    width: 28%;
  }
}
.product-header .left-lottie .blob-wrapper {
  position: absolute;
  top: -35%;
  left: -30%;
  width: 140%;
  z-index: 0;
}
.product-header .left-img {
  position: absolute;
  width: 26%;
  left: 0;
  bottom: 0;
}
@media (width <= 990px) {
  .product-header .left-img {
    bottom: auto;
    top: 40%;
  }
}
.product-header .left-img .blob-wrapper {
  position: absolute;
  top: 20%;
  left: -30%;
  width: 120%;
  z-index: 0;
}
@media (width <= 990px) {
  .product-header .left-img .blob-wrapper {
    width: 160%;
    top: 10%;
    left: -40%;
  }
}
.product-header .left-img img {
  position: relative;
  z-index: 1;
}
.product-header .right-lottie {
  position: absolute;
  z-index: 1;
  height: auto;
  right: 0;
  top: 0;
  width: 20%;
}
@media (width <= 990px) {
  .product-header .right-lottie {
    right: -9%;
    top: -3%;
    width: 32%;
  }
}
.product-header .right-img {
  position: absolute;
  width: 30%;
  right: 0;
  bottom: 0;
}
@media (width <= 990px) {
  .product-header .right-img {
    bottom: auto;
    top: 55%;
  }
}
.product-header .right-img .blob-wrapper {
  position: absolute;
  top: 0;
  right: -40%;
  width: 120%;
  z-index: 0;
}
.product-header .right-img img {
  position: relative;
  z-index: 1;
}
.product-header .col-11 {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.product-header .col-11 .title {
  padding: 7rem 0 2rem;
}
@media (width <= 990px) {
  .product-header .col-11 .title {
    padding: 5rem 0 2rem;
  }
}
.product-header .col-11 h1 {
  line-height: 1;
  font-size: 5.4375rem;
}
.product-header .col-11 h2 {
  font-size: 3.0625rem;
  font-family: "Poppins ExtraLight", sans-serif;
}
.product-header .col-11 img {
  width: 40.625rem;
}
@media (width <= 990px) {
  .product-header .col-11 img {
    width: 100%;
  }
}
@media (width < 768px) {
  .product-header .col-11 img {
    width: 31.25rem;
  }
}

.recipes {
  margin: 1rem 0;
}
.recipes swiper-slide .row {
  justify-content: space-evenly;
  align-items: center;
}
@media (width <= 990px) {
  .recipes swiper-slide .row {
    justify-content: center;
  }
}
.recipes swiper-slide .preview img,
.recipes swiper-slide .preview video {
  height: auto;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  border-radius: var(--br-default);
}
@media (width <= 990px) {
  .recipes swiper-slide .preview {
    margin-bottom: 3rem;
  }
}
.recipes swiper-slide .text h3 {
  font-family: "Poppins SemiBold", sans-serif;
  font-size: 1.375rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 28px;
  width: 65%;
}
@media (width <= 990px) {
  .recipes swiper-slide .text h3 {
    width: 100%;
  }
}
.recipes swiper-slide .text .recipe-tags ul {
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0;
  gap: 2rem;
  padding: 0;
  list-style: none;
}
.recipes swiper-slide .text .recipe-tags ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: "Poppins SemiBold", sans-serif;
  font-size: 1.25rem;
  letter-spacing: 2.5px;
}
@media (width <= 990px) {
  .recipes swiper-slide .text .recipe-tags ul li {
    font-size: 0.875rem;
  }
  .recipes swiper-slide .text .recipe-tags ul li span {
    font-size: 1.25rem;
  }
}
.recipes swiper-slide .text .recipe-tags ul li span {
  color: #ffb000;
}
.recipes swiper-slide .text p {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  margin: 1rem 0;
}
.recipes .interface-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
@media (width <= 990px) {
  .recipes .interface-wrapper {
    margin-top: 2rem;
  }
}
.recipes .interface-wrapper #recipes-slider-prev,
.recipes .interface-wrapper #recipes-slider-next {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.recipes .interface-wrapper #recipes-slider-prev:hover,
.recipes .interface-wrapper #recipes-slider-next:hover {
  color: #ffb000;
}
.recipes .interface-wrapper #recipes-slider-prev.swiper-button-disabled,
.recipes .interface-wrapper #recipes-slider-next.swiper-button-disabled {
  pointer-events: none;
  color: #cfcfcf;
}
.recipes .interface-wrapper .recipes-slider-pagination {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 0 1.2rem;
}
.recipes .interface-wrapper .recipes-slider-pagination .swiper-pagination-bullet {
  position: relative;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #cfcfcf;
  cursor: pointer;
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}
.recipes .interface-wrapper .recipes-slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  transform: scale(1.6);
  background-color: #ffb000;
  pointer-events: none;
}

.blob-wrapper {
  --time: 45s;
  --amount: 10;
  pointer-events: none;
}
.blob-wrapper svg {
  animation: blob-skew calc(var(--time, 30s) * 0.5) linear 0s infinite;
  transform-origin: center;
}
.blob-wrapper svg path {
  animation: blob-scale calc(var(--time, 30s) * 0.5) ease-in-out 0s infinite;
  transform-origin: center;
}

@keyframes blob-turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes blob-skew {
  0% {
    transform: skewY(0deg);
  }
  13% {
    transform: skewY(calc(1.8deg * var(--amount, 2)));
  }
  18% {
    transform: skewY(calc(2.2deg * var(--amount, 2)));
  }
  24% {
    transform: skewY(calc(2.48deg * var(--amount, 2)));
  }
  25% {
    transform: skewY(calc(2.5deg * var(--amount, 2)));
  }
  26% {
    transform: skewY(calc(2.48deg * var(--amount, 2)));
  }
  32% {
    transform: skewY(calc(2.2deg * var(--amount, 2)));
  }
  37% {
    transform: skewY(calc(1.8deg * var(--amount, 2)));
  }
  50% {
    transform: skewY(0deg);
  }
  63% {
    transform: skewY(calc(-1.8deg * var(--amount, 2)));
  }
  68% {
    transform: skewY(calc(-2.2deg * var(--amount, 2)));
  }
  74% {
    transform: skewY(calc(-2.48deg * var(--amount, 2)));
  }
  75% {
    transform: skewY(calc(-2.5deg * var(--amount, 2)));
  }
  76% {
    transform: skewY(calc(-2.48deg * var(--amount, 2)));
  }
  82% {
    transform: skewY(calc(-2.2deg * var(--amount, 2)));
  }
  87% {
    transform: skewY(calc(-1.8deg * var(--amount, 2)));
  }
  100% {
    transform: skewY(0deg);
  }
}
@keyframes blob-scale {
  0% {
    transform: scaleX(0.9) scaleY(1);
  }
  25% {
    transform: scaleX(0.9) scaleY(0.9);
  }
  50% {
    transform: scaleX(1) scaleY(0.9);
  }
  75% {
    transform: scaleX(0.9) scaleY(0.9);
  }
  100% {
    transform: scaleX(0.9) scaleY(1);
  }
}
.blob-slider {
  position: relative;
  z-index: 1;
}
.blob-slider swiper-slide {
  transition: all 0.65s -0.05s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform-origin: center;
  transform: scale(0.8);
  opacity: 1 !important;
}
.blob-slider swiper-slide img *,
.blob-slider swiper-slide video *,
.blob-slider swiper-slide a * {
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.2));
}
.blob-slider swiper-slide.swiper-slide-active {
  transform: scale(1.1);
  opacity: 1 !important;
}
@media (width <= 990px) {
  .blob-slider swiper-slide.swiper-slide-active {
    transform: scale(0.95);
  }
}
.blob-slider swiper-container {
  margin: -2rem 14rem;
}
@media (width <= 990px) {
  .blob-slider swiper-container {
    margin: -4rem 0;
  }
}
.blob-slider swiper-container .swiper {
  padding: 4rem 0;
}
@media (width <= 990px) {
  .blob-slider .interface-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 24%;
    margin: 0 auto;
    padding: 1rem 0 0;
  }
}
@media (width < 768px) {
  .blob-slider .interface-wrapper {
    width: 45%;
  }
}
@media (width < 768px) {
  .blob-slider {
    margin: 0 0 6rem;
  }
}
.blob-slider img,
.blob-slider video {
  width: 100%;
  height: auto;
  vertical-align: middle;
  overflow: hidden;
  border-radius: var(--br-default);
}
.blob-slider img {
  aspect-ratio: 740/550;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.blob-slider .slider-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: opacity 0.5s;
  cursor: pointer;
  font-size: 1.875rem;
  color: #ffb000;
}
@media (width <= 990px) {
  .blob-slider .slider-nav {
    position: relative;
    top: 0;
    transform: none;
  }
}
.blob-slider .slider-nav::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #414141;
  border-radius: 50%;
  width: 3.75rem;
  height: 3.75rem;
  filter: drop-shadow(0 0 0.8rem rgba(0, 0, 0, 0.4));
}
@media (width <= 990px) {
  .blob-slider .slider-nav::before {
    filter: drop-shadow(0 0 0.9rem rgba(0, 0, 0, 0.2));
  }
}
.blob-slider .slider-nav.prev {
  left: 10%;
}
@media (width <= 990px) {
  .blob-slider .slider-nav.prev {
    left: auto;
    transform: translate(100%, 0);
  }
}
.blob-slider .slider-nav.next {
  right: 10%;
}
@media (width <= 990px) {
  .blob-slider .slider-nav.next {
    right: auto;
    transform: translate(-100%, 0);
  }
}
.blob-slider .slider-nav.swiper-button-disabled {
  opacity: 0.3;
  pointer-events: none;
}

:root {
  --br-default: 60px;
}

@media (width <= 990px) {
  :root {
    --br-default: 40px;
  }
}
@media (width < 768px) {
  :root {
    --br-default: 30px;
  }
}
.btn {
  background: #414141;
  outline-offset: -6px;
  border-radius: 2rem;
  padding: 1rem 2.8rem;
  font-size: 0.875rem;
  font-family: "Poppins Bold", sans-serif;
  text-decoration: none;
  transition: all 0.4s ease-out;
}
@media (width < 768px) {
  .btn {
    font-size: 1rem;
  }
}
.btn.btn-blob {
  overflow: hidden;
  position: relative;
  display: inline-block;
  background-color: #414141;
}
.btn.btn-blob * {
  pointer-events: none;
}
.btn.btn-blob .btn-label {
  position: relative;
  z-index: 2;
  color: #ffb000;
  transition: all 0.3s ease-out;
}
.btn.btn-blob .blobs-wrapper {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: url("#honey");
}
.btn.btn-blob .blobs-wrapper .inner-blob {
  position: absolute;
  height: 100%;
  width: 25%;
  border-radius: 100%;
  background-color: #ffb000;
  transform: translate3d(0, -150%, 0) scale(1.7);
  transition: transform 0.45s;
}
@supports (filter: url("#honey")) {
  .btn.btn-blob .blobs-wrapper .inner-blob {
    transform: translate3d(0, -150%, 0) scale(1.7);
  }
}
.btn.btn-blob .blobs-wrapper .inner-blob:nth-child(1) {
  left: 0%;
  transition-delay: 0s;
}
.btn.btn-blob .blobs-wrapper .inner-blob:nth-child(2) {
  left: 30%;
  transition-delay: 0.08s;
}
.btn.btn-blob .blobs-wrapper .inner-blob:nth-child(3) {
  left: 60%;
  transition-delay: 0.16s;
}
.btn.btn-blob .blobs-wrapper .inner-blob:nth-child(4) {
  left: 90%;
  transition-delay: 0.24s;
}
.btn.btn-blob.active {
  background-color: #ffb000;
}
.btn.btn-blob.active .btn-label {
  color: #414141;
}
.btn.btn-blob:hover {
  background-color: #414141;
}
.btn.btn-blob:hover .btn-label {
  color: #414141;
}
.btn.btn-blob:hover .blobs-wrapper .inner-blob {
  transform: translateZ(0) scale(1.4);
}
@supports (filter: url("#honey")) {
  .btn.btn-blob:hover .blobs-wrapper .inner-blob {
    transform: translateZ(0) scale(1.4);
  }
}
@media (width < 768px) {
  .btn.btn-blob:hover {
    background-color: #ffb000;
  }
  .btn.btn-blob:hover .blobs-wrapper {
    display: none;
  }
}

.column-gallery .column-gallery-swiper .column {
  height: auto;
}
.column-gallery .column-gallery-swiper .column img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.double-column {
  position: relative;
  z-index: 2;
  display: block;
  margin: 1rem 0;
}
@media (width <= 990px) {
  .double-column {
    text-align: center;
    margin: 3rem 0;
  }
}
.double-column .blob-wrapper {
  position: absolute;
  display: none;
  top: -25%;
  right: -8%;
  width: 70%;
  z-index: 0;
  transform: rotate(400deg) skew(7deg, -28deg);
}
@media (width <= 990px) {
  .double-column .blob-wrapper {
    top: auto;
    bottom: -25%;
    width: 125%;
    right: -15%;
  }
}
@media (width < 768px) {
  .double-column .blob-wrapper {
    top: auto;
    bottom: -22%;
    width: 165%;
    right: -30%;
  }
}
.double-column .blob-wrapper svg {
  animation: none;
}
.double-column .trace-wrapper {
  position: absolute;
  display: none;
  top: 50%;
  left: -2%;
  width: 16%;
  z-index: 0;
}
.double-column .trace-wrapper svg {
  width: 100%;
  height: auto;
}
.double-column .flower-lottie {
  display: none;
}
.double-column .title-mobile {
  display: none;
}
@media (width <= 990px) {
  .double-column .title-mobile {
    display: block;
  }
}
.double-column .title-mobile h5 {
  margin: 0 0 1rem;
}
.double-column .title-desktop {
  display: block;
}
@media (width <= 990px) {
  .double-column .title-desktop {
    display: none;
  }
}
.double-column h5 {
  margin: 0 0 2.5rem;
}
@media (width <= 1280px) {
  .double-column .col-11 {
    width: 100%;
    padding: 0;
  }
}
.double-column .content {
  align-items: center;
}
.double-column .content .column {
  position: relative;
  padding: 0 2rem;
}
.double-column .content .column.narrow-col {
  padding-left: 16rem;
}
@media (width <= 990px) {
  .double-column .content .column.narrow-col {
    padding-left: 15px;
  }
}
.double-column .content .column .image img {
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  overflow: hidden;
  border-radius: var(--br-default);
  filter: drop-shadow(0 0 1.5rem rgba(0, 0, 0, 0.3));
}
.double-column .content .column .video video {
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  overflow: hidden;
  border-radius: var(--br-default);
  filter: drop-shadow(0 0 1.5rem rgba(0, 0, 0, 0.3));
}
.double-column .content .column .text {
  width: 80%;
}
@media (width <= 1280px) {
  .double-column .content .column .text {
    width: 100%;
    margin-right: 0;
  }
}
@media (width <= 990px) {
  .double-column .content .column .text {
    width: 80%;
    margin: 1rem auto 0;
  }
}
@media (width < 768px) {
  .double-column .content .column .text {
    width: 100%;
  }
}
.double-column .content .column.text-right {
  text-align: right;
}
.double-column .content .column.text-right .text {
  width: 100%;
}
.double-column.last .row .column {
  text-align: right;
}
.double-column.last .row .column .image img {
  overflow: hidden;
  border-radius: var(--br-default);
  filter: drop-shadow(0 0 1.5rem rgba(0, 0, 0, 0.3));
  max-height: 650px;
}
@media (width <= 990px) {
  .double-column.last .row .column .image img {
    max-height: unset;
    float: none;
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (width <= 990px) {
  .double-column.last .row .column {
    text-align: center;
  }
}
.double-column.last .row .column .text {
  width: 100%;
  margin: 0 0 0 auto;
}
@media (width <= 990px) {
  .double-column.last .row .column .text {
    margin: 1.5rem 0 0;
  }
}
@media (width <= 990px) {
  .double-column.last .row .column:last-child {
    order: -1;
  }
}

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  position: relative;
  flex-direction: column;
  text-align: center;
  gap: 2.5rem;
}
.error-page h1 {
  font-size: 4rem;
}
.error-page h1 span {
  color: #ffb000;
}
.error-page .left-lottie {
  position: absolute;
  z-index: 1;
  left: 5%;
  top: 0;
  width: 18%;
}
@media (width <= 990px) {
  .error-page .left-lottie {
    left: 0;
    width: 28%;
  }
}
.error-page .left-lottie .blob-wrapper {
  position: absolute;
  top: -35%;
  left: -30%;
  width: 140%;
  z-index: 0;
}
.error-page .right-img {
  position: absolute;
  width: 30%;
  right: 5%;
  top: 50%;
}
@media (width <= 990px) {
  .error-page .right-img {
    bottom: auto;
    top: 22%;
  }
}
.error-page .right-img .blob-wrapper {
  position: absolute;
  top: 0;
  right: -40%;
  width: 120%;
  z-index: 0;
}

.footer {
  position: relative;
  padding: 4.5rem 0 4rem;
}
@media (width <= 990px) {
  .footer {
    padding: 3.5rem 0 3rem;
  }
}
@media (width < 768px) {
  .footer {
    padding: 3.5rem 0 1.5rem;
  }
}
.footer .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 95%;
  padding: 0;
}
@media (width <= 990px) {
  .footer .content {
    flex-direction: column;
    align-items: center;
    gap: 2.5rem 0;
  }
}
.footer .content .text,
.footer .content .logo,
.footer .content .links {
  flex: 33%;
}
.footer .content .text {
  color: #a0a0a0;
}
@media (width <= 1280px) {
  .footer .content .text {
    width: 25%;
    flex: 0 0 auto;
  }
}
@media (width <= 990px) {
  .footer .content .text {
    width: 60%;
    flex: 0 0 auto;
    text-align: center;
    font-size: 1.25rem;
  }
}
@media (width < 768px) {
  .footer .content .text {
    width: 80%;
  }
}
.footer .content .logo {
  height: 3.5rem;
  pointer-events: none;
}
@media (width <= 990px) {
  .footer .content .logo {
    order: -1;
    width: 17.5rem;
    height: auto;
  }
}
.footer .content .links {
  display: flex;
  flex-wrap: wrap;
  gap: 0 1.25rem;
}
@media (width <= 990px) {
  .footer .content .links {
    gap: 0;
  }
}
.footer .content .links a {
  position: relative;
  color: #a0a0a0;
  transition: color 0.3s ease-out;
}
@media (width <= 990px) {
  .footer .content .links a {
    text-align: center;
    width: 33.3333333333%;
    margin: 0;
  }
}
.footer .content .links a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #242b35;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.3s ease-out;
}
.footer .content .links a:hover {
  color: #242b35;
}
.footer .content .links a:hover::before {
  transform: scaleX(1);
}
.footer .content .links .pixel-logo {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}
@media (width <= 990px) {
  .footer .content .links .pixel-logo {
    margin: 2rem 0 1rem;
    width: 100%;
    justify-content: center;
  }
}
.footer .content .links .pixel-logo::before {
  content: "";
  display: none;
}
.footer .content .links .pixel-logo img {
  width: auto;
  height: 0.9375rem;
}
@media (width <= 990px) {
  .footer .content .links .pixel-logo img {
    height: 1.125rem;
  }
}

.full-text {
  position: relative;
  z-index: 2;
  margin: 1rem 0;
}
@media (width <= 990px) {
  .full-text {
    width: 80%;
  }
}
@media (width < 768px) {
  .full-text {
    width: 100%;
  }
}
.full-text .content {
  text-align: center;
}
.full-text .content h5 {
  margin: 0 0 2.5rem;
}
@media (width <= 990px) {
  .full-text .content h5 {
    margin: 0 0 1.5rem;
  }
}
.full-text .content h5:last-child {
  margin: 4rem 0 0;
}
.full-text.iubenda {
  margin: 0;
}
.full-text.iubenda .content {
  text-align: left;
}

.grid-gallery .justified-grid-gallery {
  overflow: visible;
}
.grid-gallery .justified-grid-gallery .grid-item {
  overflow: hidden;
}
.grid-gallery .justified-grid-gallery .grid-item img {
  transition: transform 0.3s ease-in-out;
}
.grid-gallery .justified-grid-gallery .grid-item:hover img {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

:root {
  --header-height: 5rem;
}
@media (width <= 990px) {
  :root {
    --header-height: 3.5rem;
  }
}
@media (width < 768px) {
  :root {
    --header-height: 4rem;
  }
}

.blur-overlay {
  opacity: 0;
  visibility: hidden;
}

.page-header {
  position: relative;
  z-index: 999;
}
.page-header .content {
  position: sticky;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s 0.05s ease-out;
}
@media (width <= 990px) {
  .page-header .content {
    justify-content: space-between;
  }
}
.page-header .content .breadcrumb ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 0 1rem;
  list-style: none;
}
.page-header .content .breadcrumb ul li {
  position: relative;
  font-family: "Poppins Medium", sans-serif;
  font-size: 0.95rem;
}
.page-header .content .breadcrumb ul li * {
  font-size: 0.95rem;
}
.page-header .content .breadcrumb ul li::after {
  content: "›";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(200%, -49%);
  font-family: "Poppins Light", sans-serif;
  font-size: 1.2rem;
  color: #414141;
  opacity: 0.4;
}
.page-header .content .breadcrumb ul li a {
  color: #414141;
  transition: opacity 0.3s ease;
  opacity: 0.4;
}
.page-header .content .breadcrumb ul li a:hover {
  opacity: 1;
}
.page-header .content .breadcrumb ul li.target {
  color: #ffb000;
}
.page-header .content .breadcrumb ul li:last-child::after {
  display: none;
}
@media (width <= 990px) {
  .page-header .content .breadcrumb {
    display: none;
  }
}
.page-header .content .logo {
  text-align: center;
}
@media (width <= 990px) {
  .page-header .content .logo {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    max-width: -moz-max-content;
    max-width: max-content;
  }
}
.page-header .content .logo img {
  width: 12.8125rem;
  height: auto;
  pointer-events: none;
}
.page-header .content .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
@media (width <= 990px) {
  .page-header .content .buttons {
    width: auto;
    max-width: -moz-max-content;
    max-width: max-content;
  }
}
.page-header .content .buttons .language-toggle {
  position: relative;
  margin: 0 2.5rem 0 1rem;
  padding: 0 0 0 2rem;
  text-transform: uppercase;
}
.page-header .content .buttons .language-toggle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 80%;
  width: 1px;
  background-color: #fff;
  transform: translateY(-50%);
}
.page-header .content .buttons .language-toggle .inner-language {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.page-header .content .buttons .language-toggle .inner-language .material-symbols-outlined {
  transform: rotate(0);
  transform-origin: center;
  transition: transform 0.15s ease-out;
}
.page-header .content .buttons .language-toggle .inner-language.clicked .material-symbols-outlined {
  transform: rotate(-180deg);
}
@media (width <= 990px) {
  .page-header .content .buttons .language-toggle {
    display: none;
  }
}
.page-header .content .buttons .nav-lang {
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  width: 100%;
  display: block;
  background-color: #000;
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  border: 0.5px solid #3392ff;
}
.page-header .content .buttons .nav-lang.appear {
  transform: scaleY(1) translate(0, 110%);
  opacity: 1;
  visibility: visible;
}
.page-header .content .buttons .nav-lang ul {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  list-style: none;
}
.page-header .content .buttons .nav-lang ul li {
  color: #fff;
  text-transform: uppercase;
}
.page-header .content .buttons .nav-lang ul li.active {
  color: #3392ff;
}
.page-header .content .buttons .nav-lang ul li a {
  color: #fff;
  transition: color 0.3s ease-out;
}
.page-header .content .buttons .nav-lang ul li a:hover {
  color: #3392ff;
}
.page-header .content .buttons .menu-icon {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.38rem 0;
}
.page-header .content .buttons .menu-icon .line {
  position: relative;
  display: block;
  width: 2.625rem;
  height: 0.3125rem;
  background-color: #414141;
  transition: transform 0.35s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-radius: 50px;
}
.page-header .content .buttons .menu-icon .line:nth-child(1) {
  transform-origin: top left;
}
.page-header .content .buttons .menu-icon .line:nth-child(2) {
  background-color: #ffb000;
}
.page-header .content .buttons .menu-icon .line:nth-child(3) {
  transform-origin: bottom left;
}
.page-header .content .buttons .menu-icon:hover .line:nth-child(1) {
  transform: translateY(-30%);
}
.page-header .content .buttons .menu-icon:hover .line:nth-child(3) {
  transform: translateY(30%);
}
.page-header .content .buttons .menu-icon.is-animating {
  pointer-events: none;
}
.page-header .content .buttons .menu-icon.is-animating * {
  pointer-events: none;
}
.page-header .header-dropdown {
  position: fixed;
  top: 0;
  left: 0;
  height: 96vh;
  width: 100vw;
  z-index: 88;
  opacity: 0;
  transform-origin: top center;
  transform: scaleY(0);
  transition: all 0.15s 0s ease-out;
}
@media (width <= 1366px) {
  .page-header .header-dropdown {
    height: 100vh;
  }
}
@media (width <= 990px) {
  .page-header .header-dropdown {
    height: 100vh;
  }
}
.page-header .header-dropdown .row {
  position: relative;
  z-index: 1;
  height: 95%;
}
@media (width <= 1366px) {
  .page-header .header-dropdown .row {
    height: 100%;
  }
}
@media (width <= 1280px) {
  .page-header .header-dropdown .row {
    height: 90%;
  }
}
.page-header .header-dropdown .nav-list {
  position: relative;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  gap: 1.2rem;
  padding: 10vw 0 0 10vw;
}
@media (width <= 990px) {
  .page-header .header-dropdown .nav-list {
    justify-content: start;
    padding: 15vw calc(15vw - 15px) 0;
  }
}
@media (width < 768px) {
  .page-header .header-dropdown .nav-list {
    padding: 20vw calc(15vw - 15px) 0;
  }
}
.page-header .header-dropdown .nav-list li {
  transition: transform 0.3s ease-out;
}
.page-header .header-dropdown .nav-list li:hover {
  transform: translateX(15%);
}
.page-header .header-dropdown .nav-list li.active {
  pointer-events: none;
}
.page-header .header-dropdown .nav-list li.active * {
  pointer-events: none;
}
.page-header .header-dropdown .nav-list .nav-entry {
  position: relative;
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
  font-family: "Poppins Medium", sans-serif;
}
.page-header .header-dropdown .nav-list .nav-entry.active {
  color: #414141;
}
.page-header .header-dropdown .nav-list .nav-entry span {
  position: relative;
  display: block;
}
.page-header .header-dropdown .contacts {
  display: flex;
  height: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}
@media (width <= 1280px) {
  .page-header .header-dropdown .contacts {
    height: 100%;
  }
}
@media (width <= 990px) {
  .page-header .header-dropdown .contacts {
    padding: 10vw 15vw 0;
    justify-content: start;
  }
}
.page-header .header-dropdown .contacts img {
  width: 75%;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 1rem -5rem;
}
@media (width <= 1280px) {
  .page-header .header-dropdown .contacts img {
    width: 80%;
  }
}
@media (width <= 990px) {
  .page-header .header-dropdown .contacts img {
    display: none;
  }
}
.page-header .header-dropdown .contacts p {
  font-family: "Poppins Medium", sans-serif;
  font-size: 1.375rem;
  color: #fff;
}
.page-header .header-dropdown .contacts p a {
  position: relative;
}
.page-header .header-dropdown .contacts p a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #414141;
  transform-origin: left center;
  transform: scaleX(0);
  z-index: 0;
  transition: transform 0.25s ease-out;
}
.page-header .header-dropdown .contacts p a:hover::after {
  transform: scaleX(1);
}
.page-header .header-dropdown .contacts p span {
  font-family: "Poppins Medium", sans-serif;
  font-size: 1.375rem;
  color: #414141;
}
.page-header .header-dropdown .contacts .mobile-nav-lang {
  display: none;
}
.page-header .header-dropdown .contacts .mobile-nav-lang ul {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 3rem;
}
.page-header .header-dropdown .contacts .mobile-nav-lang ul li {
  color: #fff;
  text-transform: uppercase;
  font-family: "Poppins Medium", sans-serif;
  font-size: 1.75rem;
}
.page-header .header-dropdown .contacts .mobile-nav-lang ul li.active {
  color: #414141;
}
.page-header .header-dropdown .contacts .mobile-nav-lang ul li a {
  color: #fff;
  transition: color 0.3s ease-out;
  font-size: 1.75rem;
}
.page-header .header-dropdown .contacts .mobile-nav-lang ul li a:hover {
  color: #414141;
}
@media (width <= 990px) {
  .page-header .header-dropdown .contacts .mobile-nav-lang {
    display: block;
  }
}
.page-header .header-dropdown .bg-svg-morph {
  position: absolute;
  top: 0;
  width: 100%;
  transform: scaleX(1.05) scaleY(0.95) translate(0, -4%);
}
@media (width <= 1366px) {
  .page-header .header-dropdown .bg-svg-morph {
    transform: scaleX(1.05) scaleY(1.9) translate(0, -5%);
  }
}
@media (width <= 990px) {
  .page-header .header-dropdown .bg-svg-morph {
    width: auto;
    height: 100%;
    transform: scaleX(1.05) scaleY(1.15) translate(0, -12%);
  }
}
.page-header .header-dropdown .bg-svg-morph svg {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
@media (width <= 990px) {
  .page-header .header-dropdown .bg-svg-morph svg {
    width: auto;
    height: 100%;
  }
}
.page-header .header-dropdown .bg-svg-morph svg path {
  fill: #ffb000;
}

html.menu-open .page-header .content {
  transition: background-color 0s 0s ease-out;
  background: transparent;
}
html.menu-open .page-header .content .logo {
  transition: filter 0.15s 0.05s ease-out;
  filter: brightness(0);
}
html.menu-open .page-header .content .breadcrumb {
  opacity: 0;
  visibility: hidden;
}
html.menu-open .page-header .content .buttons .language-toggle {
  opacity: 0;
  visibility: hidden;
}
html.menu-open .page-header .content .buttons .menu-icon .line:nth-child(1) {
  transform: rotate(42deg) translateY(-200%);
}
html.menu-open .page-header .content .buttons .menu-icon .line:nth-child(2) {
  display: none;
}
html.menu-open .page-header .content .buttons .menu-icon .line:nth-child(3) {
  transform: rotate(-42deg) translateY(200%);
}
html.menu-open .page-header .header-dropdown {
  transition: all 0.12s 0.05s ease-out;
  transform: scaleY(1);
  opacity: 1;
}
html.menu-open .blur-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  background-color: rgba(255, 255, 255, 0.45);
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
}

.lazy-load * {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.lazy-load .blob-wrapper {
  opacity: 1;
}
.lazy-load .blob-wrapper * {
  opacity: 1;
}
.lazy-load .lazy-item * {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.lazy-load .lazy-item:nth-child(1n) {
  transition-delay: 0.15s;
}
.lazy-load .lazy-item:nth-child(2n) {
  transition-delay: 0.3s;
}
.lazy-load .lazy-item:nth-child(3n) {
  transition-delay: 0.45s;
}
.lazy-load .lazy-item:nth-child(4n) {
  transition-delay: 0.6s;
}
.lazy-load .lazy-item:nth-child(5n) {
  transition-delay: 0.75s;
}
.lazy-load .lazy-item:nth-child(6n) {
  transition-delay: 0.9s;
}
.lazy-load .lazy-item:nth-child(7n) {
  transition-delay: 1.05s;
}
.lazy-load .lazy-item:nth-child(8n) {
  transition-delay: 1.2s;
}
.lazy-load .lazy-item:nth-child(9n) {
  transition-delay: 1.35s;
}
.lazy-load .lazy-item:nth-child(10n) {
  transition-delay: 1.5s;
}
.lazy-load .lazy-item:nth-child(11n) {
  transition-delay: 1.65s;
}
.lazy-load .lazy-item:nth-child(12n) {
  transition-delay: 1.8s;
}
.lazy-load .lazy-item:nth-child(13n) {
  transition-delay: 1.95s;
}
.lazy-load .lazy-item:nth-child(14n) {
  transition-delay: 2.1s;
}
.lazy-load .lazy-item:nth-child(15n) {
  transition-delay: 2.25s;
}
.lazy-load .lazy-item:nth-child(16n) {
  transition-delay: 2.4s;
}
.lazy-load .lazy-item:nth-child(17n) {
  transition-delay: 2.55s;
}
.lazy-load .lazy-item:nth-child(18n) {
  transition-delay: 2.7s;
}
.lazy-load .lazy-item:nth-child(19n) {
  transition-delay: 2.85s;
}
.lazy-load .lazy-item:nth-child(20n) {
  transition-delay: 3s;
}
.lazy-load .lazy-item:nth-child(21n) {
  transition-delay: 3.15s;
}
.lazy-load .lazy-item:nth-child(22n) {
  transition-delay: 3.3s;
}
.lazy-load .lazy-item:nth-child(23n) {
  transition-delay: 3.45s;
}
.lazy-load .lazy-item:nth-child(24n) {
  transition-delay: 3.6s;
}
.lazy-load .lazy-item:nth-child(25n) {
  transition-delay: 3.75s;
}
.lazy-load .lazy-item:nth-child(26n) {
  transition-delay: 3.9s;
}
.lazy-load .lazy-item:nth-child(27n) {
  transition-delay: 4.05s;
}
.lazy-load .lazy-item:nth-child(28n) {
  transition-delay: 4.2s;
}
.lazy-load .lazy-item:nth-child(29n) {
  transition-delay: 4.35s;
}
.lazy-load .lazy-item:nth-child(30n) {
  transition-delay: 4.5s;
}
.lazy-load .lazy-item:nth-child(31n) {
  transition-delay: 4.65s;
}
.lazy-load .lazy-item:nth-child(32n) {
  transition-delay: 4.8s;
}
.lazy-load .lazy-item:nth-child(33n) {
  transition-delay: 4.95s;
}
.lazy-load .lazy-item:nth-child(34n) {
  transition-delay: 5.1s;
}
.lazy-load .lazy-item:nth-child(35n) {
  transition-delay: 5.25s;
}
.lazy-load .lazy-item:nth-child(36n) {
  transition-delay: 5.4s;
}
.lazy-load .lazy-item:nth-child(37n) {
  transition-delay: 5.55s;
}
.lazy-load .lazy-item:nth-child(38n) {
  transition-delay: 5.7s;
}
.lazy-load .lazy-item:nth-child(39n) {
  transition-delay: 5.85s;
}
.lazy-load .lazy-item:nth-child(40n) {
  transition-delay: 6s;
}
.lazy-load .lazy-item:nth-child(41n) {
  transition-delay: 6.15s;
}
.lazy-load .lazy-item:nth-child(42n) {
  transition-delay: 6.3s;
}
.lazy-load .lazy-item:nth-child(43n) {
  transition-delay: 6.45s;
}
.lazy-load .lazy-item:nth-child(44n) {
  transition-delay: 6.6s;
}
.lazy-load .lazy-item:nth-child(45n) {
  transition-delay: 6.75s;
}
.lazy-load .lazy-item:nth-child(46n) {
  transition-delay: 6.9s;
}
.lazy-load .lazy-item:nth-child(47n) {
  transition-delay: 7.05s;
}
.lazy-load .lazy-item:nth-child(48n) {
  transition-delay: 7.2s;
}
.lazy-load .lazy-item:nth-child(49n) {
  transition-delay: 7.35s;
}
.lazy-load .lazy-item:nth-child(50n) {
  transition-delay: 7.5s;
}
.lazy-load .lazy-item:nth-child(51n) {
  transition-delay: 7.65s;
}
.lazy-load .lazy-item:nth-child(52n) {
  transition-delay: 7.8s;
}
.lazy-load .lazy-item:nth-child(53n) {
  transition-delay: 7.95s;
}
.lazy-load .lazy-item:nth-child(54n) {
  transition-delay: 8.1s;
}
.lazy-load .lazy-item:nth-child(55n) {
  transition-delay: 8.25s;
}
.lazy-load .lazy-item:nth-child(56n) {
  transition-delay: 8.4s;
}
.lazy-load .lazy-item:nth-child(57n) {
  transition-delay: 8.55s;
}
.lazy-load .lazy-item:nth-child(58n) {
  transition-delay: 8.7s;
}
.lazy-load .lazy-item:nth-child(59n) {
  transition-delay: 8.85s;
}
.lazy-load .lazy-item:nth-child(60n) {
  transition-delay: 9s;
}
.lazy-load .lazy-item:nth-child(61n) {
  transition-delay: 9.15s;
}
.lazy-load .lazy-item:nth-child(62n) {
  transition-delay: 9.3s;
}
.lazy-load .lazy-item:nth-child(63n) {
  transition-delay: 9.45s;
}
.lazy-load .lazy-item:nth-child(64n) {
  transition-delay: 9.6s;
}
.lazy-load .lazy-item:nth-child(65n) {
  transition-delay: 9.75s;
}
.lazy-load .lazy-item:nth-child(66n) {
  transition-delay: 9.9s;
}
.lazy-load .lazy-item:nth-child(67n) {
  transition-delay: 10.05s;
}
.lazy-load .lazy-item:nth-child(68n) {
  transition-delay: 10.2s;
}
.lazy-load .lazy-item:nth-child(69n) {
  transition-delay: 10.35s;
}
.lazy-load .lazy-item:nth-child(70n) {
  transition-delay: 10.5s;
}
.lazy-load .lazy-item:nth-child(71n) {
  transition-delay: 10.65s;
}
.lazy-load .lazy-item:nth-child(72n) {
  transition-delay: 10.8s;
}
.lazy-load .lazy-item:nth-child(73n) {
  transition-delay: 10.95s;
}
.lazy-load .lazy-item:nth-child(74n) {
  transition-delay: 11.1s;
}
.lazy-load .lazy-item:nth-child(75n) {
  transition-delay: 11.25s;
}
.lazy-load .lazy-item:nth-child(76n) {
  transition-delay: 11.4s;
}
.lazy-load .lazy-item:nth-child(77n) {
  transition-delay: 11.55s;
}
.lazy-load .lazy-item:nth-child(78n) {
  transition-delay: 11.7s;
}
.lazy-load .lazy-item:nth-child(79n) {
  transition-delay: 11.85s;
}
.lazy-load .lazy-item:nth-child(80n) {
  transition-delay: 12s;
}
.lazy-load .lazy-item:nth-child(81n) {
  transition-delay: 12.15s;
}
.lazy-load .lazy-item:nth-child(82n) {
  transition-delay: 12.3s;
}
.lazy-load .lazy-item:nth-child(83n) {
  transition-delay: 12.45s;
}
.lazy-load .lazy-item:nth-child(84n) {
  transition-delay: 12.6s;
}
.lazy-load .lazy-item:nth-child(85n) {
  transition-delay: 12.75s;
}
.lazy-load .lazy-item:nth-child(86n) {
  transition-delay: 12.9s;
}
.lazy-load .lazy-item:nth-child(87n) {
  transition-delay: 13.05s;
}
.lazy-load .lazy-item:nth-child(88n) {
  transition-delay: 13.2s;
}
.lazy-load .lazy-item:nth-child(89n) {
  transition-delay: 13.35s;
}
.lazy-load .lazy-item:nth-child(90n) {
  transition-delay: 13.5s;
}
.lazy-load .lazy-item:nth-child(91n) {
  transition-delay: 13.65s;
}
.lazy-load .lazy-item:nth-child(92n) {
  transition-delay: 13.8s;
}
.lazy-load .lazy-item:nth-child(93n) {
  transition-delay: 13.95s;
}
.lazy-load .lazy-item:nth-child(94n) {
  transition-delay: 14.1s;
}
.lazy-load .lazy-item:nth-child(95n) {
  transition-delay: 14.25s;
}
.lazy-load .lazy-item:nth-child(96n) {
  transition-delay: 14.4s;
}
.lazy-load .lazy-item:nth-child(97n) {
  transition-delay: 14.55s;
}
.lazy-load .lazy-item:nth-child(98n) {
  transition-delay: 14.7s;
}
.lazy-load .lazy-item:nth-child(99n) {
  transition-delay: 14.85s;
}
.lazy-load .lazy-item:nth-child(100n) {
  transition-delay: 15s;
}
.lazy-load.loaded * {
  opacity: 1;
}
.lazy-load.loaded .lazy-item * {
  opacity: 1;
}

.preloader {
  background-color: #fff;
  width: 100vw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  overflow: hidden;
}
.preloader img {
  width: 16.875rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider {
  position: relative;
  z-index: 1;
}
.slider swiper-container {
  margin: -4rem 0;
}
.slider swiper-container .swiper {
  padding: 4rem 0;
}
@media (width < 768px) {
  .slider {
    margin: 0 0 8rem;
  }
}
.slider .blob-wrapper {
  position: absolute;
  z-index: 0;
}
.slider .blob-wrapper.top {
  left: -20%;
  width: 100%;
  top: -170%;
}
.slider .blob-wrapper.bottom {
  right: -18%;
  width: 65%;
  bottom: -135%;
}
.slider img,
.slider video {
  width: 100%;
  height: auto;
  border-radius: var(--br-default);
  overflow: hidden;
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.3));
  vertical-align: middle;
}
.slider img {
  aspect-ratio: 740/550;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
@media (width <= 990px) {
  .slider .interface-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 3rem 0 0;
  }
}
.slider .slider-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: opacity 0.5s;
  cursor: pointer;
  font-size: 1.875rem;
}
@media (width <= 990px) {
  .slider .slider-nav {
    position: relative;
    top: 0;
    transform: none;
  }
}
.slider .slider-nav::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffb000;
  border-radius: 50%;
  width: 3.75rem;
  height: 3.75rem;
  filter: drop-shadow(0 0 0.8rem rgba(0, 0, 0, 0.4));
}
@media (width <= 990px) {
  .slider .slider-nav::before {
    filter: drop-shadow(0 0 0.9rem rgba(0, 0, 0, 0.2));
  }
}
.slider .slider-nav.prev {
  left: 20%;
}
@media (width <= 990px) {
  .slider .slider-nav.prev {
    left: auto;
    transform: translate(100%, 0);
  }
}
.slider .slider-nav.next {
  right: 20%;
}
@media (width <= 990px) {
  .slider .slider-nav.next {
    right: auto;
    transform: translate(-100%, 0);
  }
}
.slider .slider-nav.swiper-button-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.triple-column {
  position: relative;
  z-index: 2;
  display: block;
  margin: 1rem 0;
}
@media (width <= 990px) {
  .triple-column {
    text-align: center;
  }
}
.triple-column .title-mobile {
  display: none;
}
@media (width <= 990px) {
  .triple-column .title-mobile {
    display: block;
  }
}
.triple-column .title-mobile h5 {
  margin: 0 0 2.5rem;
}
.triple-column .title-desktop {
  display: block;
}
@media (width <= 990px) {
  .triple-column .title-desktop {
    display: none;
  }
}
.triple-column h5 {
  margin: 0 0 2.5rem;
}
@media (width <= 1280px) {
  .triple-column .col-11 {
    width: 100%;
    padding: 0;
  }
}
.triple-column .content {
  position: relative;
  display: block;
}
.triple-column .content .column {
  position: relative;
  text-align: center;
}
@media (width <= 990px) {
  .triple-column .content .column {
    margin: 0 0 4rem;
  }
}
.triple-column .content .column.narrow-col {
  padding-left: 16rem;
}
@media (width <= 990px) {
  .triple-column .content .column.narrow-col {
    padding-left: 15px;
  }
}
.triple-column .content .column .image img {
  aspect-ratio: 709/473;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  overflow: hidden;
  border-radius: var(--br-default);
  filter: drop-shadow(0 0 1.5rem rgba(0, 0, 0, 0.3));
}
@media (width <= 990px) {
  .triple-column .content .column .image img {
    width: 85%;
  }
}
.triple-column .content .column .video video {
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  overflow: hidden;
  border-radius: var(--br-default);
  filter: drop-shadow(0 0 1.5rem rgba(0, 0, 0, 0.3));
}
.triple-column .content .column .text {
  width: 85%;
  margin: 3rem auto;
}
@media (width <= 1280px) {
  .triple-column .content .column .text {
    width: 100%;
    margin-right: 0;
  }
}
@media (width <= 990px) {
  .triple-column .content .column .text {
    width: 80%;
    margin: 3rem auto;
  }
}
.triple-column .content .column .text p {
  margin: 0.7rem 0 0;
}
.triple-column .content .column.text-right {
  text-align: right;
}
.triple-column .content .column.text-right .text {
  width: 100%;
}
.triple-column .content .pagination-ui {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0 1.8rem;
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (width <= 990px) {
  .triple-column .content .pagination-ui {
    margin-bottom: 4rem;
  }
}
.triple-column .content .pagination-ui .swiper-pagination-bullet {
  position: relative;
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: #ffb000;
  cursor: pointer;
  transform-origin: center;
  transition: transform 0.45s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.triple-column .content .pagination-ui .swiper-pagination-bullet.swiper-pagination-bullet-active {
  transform: scale(1.8);
  background-color: #414141;
  pointer-events: none;
}
.triple-column.last .row .column {
  text-align: right;
}
.triple-column.last .row .column .image img {
  height: 100%;
  width: auto;
  -o-object-fit: scale-down;
     object-fit: scale-down;
  overflow: hidden;
  border-radius: var(--br-default);
  filter: drop-shadow(0 0 1.5rem rgba(0, 0, 0, 0.3));
  max-height: 650px;
  float: left;
  aspect-ratio: unset;
}
@media (width <= 990px) {
  .triple-column.last .row .column .image img {
    max-height: unset;
    float: none;
    width: 100%;
    height: auto;
    aspect-ratio: 709/473;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (width <= 990px) {
  .triple-column.last .row .column {
    text-align: center;
  }
}
.triple-column.last .row .column .text {
  width: 100%;
  margin: 0 0 0 auto;
}
@media (width <= 990px) {
  .triple-column.last .row .column .text {
    margin: 1.5rem 0 0;
  }
}
@media (width <= 990px) {
  .triple-column.last .row .column:last-child {
    order: -1;
  }
}

.video-player {
  padding: 2rem 0;
}
.video-player .content {
  position: relative;
}
.video-player .content .left-blob {
  position: absolute;
  top: -65%;
  left: -25%;
  width: 75%;
  z-index: 1;
}
.video-player .content .right-blob {
  position: absolute;
  width: 70%;
  z-index: 1;
  bottom: -40%;
  right: -25%;
}
.video-player .content .video-grid {
  display: grid;
  background-color: #000;
  position: relative;
  z-index: 3;
}
.video-player .content .video-grid .cover {
  grid-area: 1/1/2/2;
  z-index: 1;
}
.video-player .content .video-grid .cover img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 40vw;
}
@media (width <= 990px) {
  .video-player .content .video-grid .cover img {
    max-width: 90vw;
  }
}
.video-player .content .video-grid .video {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  display: grid;
  grid-area: 1/1/2/2;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}
.video-player .content .video-grid .video iframe {
  grid-area: 1/1/2/2;
  width: 100%;
  height: 100%;
  border: 0;
  z-index: 2;
  max-width: 40vw;
}
@media (width <= 990px) {
  .video-player .content .video-grid .video iframe {
    max-width: 90vw;
  }
}
.video-player .content .video-grid .video video {
  grid-area: 1/1/2/2;
  width: 100%;
  height: 100%;
  z-index: 2;
  max-width: 40vw;
}
@media (width <= 990px) {
  .video-player .content .video-grid .video video {
    max-width: 90vw;
  }
}
.video-player .content .video-grid .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  z-index: 20;
  transform: translate(-25px, -25px);
}
.video-player .content.video-playing .video-grid .cover {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}
.video-player .content.video-playing .video-grid .video {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out;
}
.video-player .content.video-playing .video-grid .play-btn {
  display: none;
}

.contact-header {
  position: relative;
  margin: -1rem 0 0;
}
.contact-header .blob-wrapper {
  position: absolute;
  z-index: 0;
  top: -25%;
  left: -5%;
  width: 65%;
}
@media (width <= 990px) {
  .contact-header .blob-wrapper {
    top: auto;
    bottom: -25%;
    left: -15%;
    width: 145%;
  }
}
@media (width < 768px) {
  .contact-header .blob-wrapper {
    bottom: -25%;
    left: -20%;
    width: 150%;
  }
}
.contact-header .blob-wrapper.pos-right {
  top: 15%;
  right: -15%;
  left: auto;
  width: 50%;
}
@media (width <= 990px) {
  .contact-header .blob-wrapper.pos-right {
    inset: auto -35% -55% auto;
    width: 100%;
  }
}
@media (width < 768px) {
  .contact-header .blob-wrapper.pos-right {
    right: -35%;
    width: 100%;
    bottom: -45%;
  }
}
.contact-header .blob-wrapper svg {
  animation: none;
}
.contact-header .content {
  align-items: center;
  margin: 0 0 2.5rem;
}
@media (width < 768px) {
  .contact-header .content .column {
    padding: 0;
  }
}
@media (width <= 990px) {
  .contact-header .content .column:last-child {
    order: -1;
  }
}
.contact-header .content .text {
  padding: 0 5% 0 0;
}
@media (width <= 990px) {
  .contact-header .content .text {
    padding: 4rem 0 8rem;
    text-align: center;
  }
}
@media (width < 768px) {
  .contact-header .content .text {
    padding: 4rem 0;
  }
}
.contact-header .content .text h1 span {
  display: block;
}
.contact-header .content .text p {
  margin: 2.5rem 0 0;
  max-width: 50%;
}
@media (width <= 1280px) {
  .contact-header .content .text p {
    max-width: 70%;
  }
}
@media (width <= 990px) {
  .contact-header .content .text p {
    margin: 2.5rem auto 0;
  }
}
.contact-header .content .image img {
  aspect-ratio: 1.15;
}

.contact-info {
  margin: 6rem 0;
}
@media (width <= 990px) {
  .contact-info {
    margin: 3rem 0;
  }
}
.contact-info .column {
  width: 100%;
  text-align: center;
}
.contact-info .column .blob {
  height: 25rem;
  background-color: #414141;
  margin-bottom: 4rem;
}
@media (width <= 1280px) {
  .contact-info .column .blob {
    margin-bottom: 0;
  }
}
@media (width <= 990px) {
  .contact-info .column .blob {
    height: 30rem;
  }
}
.contact-info .column .blob h4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
  font-family: "Poppins Medium", sans-serif;
  font-size: 1.5rem;
  max-width: 65%;
  margin: 0 auto;
}
@media (width <= 1280px) {
  .contact-info .column .blob h4 {
    font-size: 1.25rem;
  }
}
.contact-info .column .blob .yellow {
  color: #ffb000;
}
@media (width <= 990px) {
  .contact-info .column .btn {
    margin: 2rem 0 4rem;
  }
}
@media (width < 768px) {
  .contact-info .column .btn {
    margin: 0 0 3rem;
  }
}

.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  position: relative;
}
@media (width <= 990px) {
  .coming-soon h1 {
    font-size: 3rem;
  }
}
.coming-soon h1 span {
  color: #ffb000;
}
.coming-soon .left-lottie {
  position: absolute;
  z-index: 1;
  left: 5%;
  top: 0;
  width: 18%;
}
@media (width <= 990px) {
  .coming-soon .left-lottie {
    left: 0;
    width: 28%;
  }
}
.coming-soon .left-lottie .blob-wrapper {
  position: absolute;
  top: -35%;
  left: -30%;
  width: 140%;
  z-index: 0;
}
.coming-soon .right-img {
  position: absolute;
  width: 30%;
  right: 5%;
  top: 50%;
}
@media (width <= 990px) {
  .coming-soon .right-img {
    bottom: auto;
    top: 22%;
  }
}
.coming-soon .right-img .blob-wrapper {
  position: absolute;
  top: 0;
  right: -40%;
  width: 120%;
  z-index: 0;
}
.coming-soon .right-img img {
  position: relative;
  z-index: 1;
}

.hero {
  position: relative;
  z-index: 4;
  margin-bottom: 16rem;
}
@media (width <= 1680px) {
  .hero {
    margin-bottom: 0;
  }
}
@media (width <= 990px) {
  .hero {
    margin-top: -4rem;
  }
}
.hero .blob-wrapper.top-right {
  position: absolute;
  top: -20%;
  right: -20%;
  width: 45%;
  z-index: 0;
  pointer-events: none;
}
@media (width <= 1280px) {
  .hero .blob-wrapper.top-right {
    transform: scale(1.4);
    transform-origin: center;
  }
}
@media (width <= 990px) {
  .hero .blob-wrapper.top-right {
    top: -10%;
    right: -10%;
    width: 55%;
  }
}
@media (width < 768px) {
  .hero .blob-wrapper.top-right {
    width: 65%;
  }
}
.hero .trace-wrapper {
  position: absolute;
  top: 2%;
  right: -3%;
  width: 22%;
  z-index: 2;
  pointer-events: none;
}
@media (width <= 990px) {
  .hero .trace-wrapper {
    top: 0;
    right: 0;
    width: 35%;
  }
}
@media (width < 768px) {
  .hero .trace-wrapper {
    width: 40%;
  }
}
.hero .trace-wrapper svg {
  width: 100%;
  height: auto;
}
.hero .blob-wrapper.right-bottom {
  position: absolute;
  right: -10%;
  bottom: -50%;
  width: 30%;
  z-index: 0;
}
.hero .content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80vh;
  margin-bottom: 10rem;
}
@media (width <= 1366px) {
  .hero .content {
    height: 85vh;
  }
}
@media (width <= 990px) {
  .hero .content {
    flex-flow: column-reverse wrap;
    height: auto;
  }
}
.hero .content .lottie {
  position: absolute;
  z-index: 2;
  top: -10%;
  left: 8vw;
  width: 22%;
  height: auto;
  transform: rotate(45deg);
}
@media (width <= 990px) {
  .hero .content .lottie {
    top: 72%;
    left: -8%;
    width: 26%;
    transform: rotate(-5deg);
  }
}
@media (width < 768px) {
  .hero .content .lottie {
    top: 65%;
    width: 32%;
  }
}
.hero .content .hero-text {
  position: relative;
  z-index: 1;
  padding: 4rem 0 0 8vw;
  transform: translateY(30%);
}
@media (width <= 1280px) {
  .hero .content .hero-text {
    padding: 4rem 0 0 6vw;
    transform: translateY(15%);
  }
}
@media (width <= 990px) {
  .hero .content .hero-text {
    padding: 4rem 0 0;
    transform: translateY(0);
    text-align: center;
  }
}
.hero .content .hero-text .blob-wrapper {
  position: absolute;
  top: -40%;
  left: -30%;
  width: 150%;
  z-index: -1;
}
.hero .content .hero-text .logo {
  width: 26.25rem;
  margin: 0 0 3.75rem;
}
@media (width <= 1280px) {
  .hero .content .hero-text .logo {
    width: 20rem;
  }
}
@media (width <= 990px) {
  .hero .content .hero-text .logo {
    display: none;
  }
}
.hero .content .hero-text p {
  font-size: 2.25rem;
  font-family: "Poppins Light", sans-serif;
  max-width: 70%;
}
@media (width <= 1280px) {
  .hero .content .hero-text p {
    font-size: 1.875rem;
    max-width: 75%;
  }
}
@media (width <= 990px) {
  .hero .content .hero-text p {
    max-width: 50%;
    margin: 0 auto;
  }
}
.hero .content .hero-img {
  position: relative;
  z-index: 3;
}
@media (width <= 1366px) {
  .hero .content .hero-img {
    align-self: flex-end;
  }
}
@media (width < 768px) {
  .hero .content .hero-img {
    padding-right: 0;
  }
}
.hero .content .hero-img .blob-mask {
  transform: translateY(-3rem) scale(1.08);
  transform-origin: top;
  transition: clip-path 0.5s ease-in-out;
  clip-path: url("#hero-blob1");
}
@media (width <= 1366px) {
  .hero .content .hero-img .blob-mask {
    transform: translateY(-1rem) scale(1.08);
    transform-origin: center;
  }
}
@media (width <= 1280px) {
  .hero .content .hero-img .blob-mask {
    transform: translateY(-3rem) scale(1.2);
  }
}
@media (width <= 990px) {
  .hero .content .hero-img .blob-mask {
    transform: translateY(0) scale(1);
  }
}
.hero .content .hero-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  vertical-align: middle;
}
.hero .hero-next {
  position: absolute;
  top: 60%;
  right: 0;
  transform: translateY(-50%);
  z-index: 2;
  transition: opacity 0.5s;
  cursor: pointer;
  font-size: 1.875rem;
  color: #ffb000;
}
@media (width <= 990px) {
  .hero .hero-next {
    top: 98%;
    right: 40%;
    transform: translate(400%, -50%);
  }
}
.hero .hero-next::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.45s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: #414141;
  border-radius: 50%;
  width: 3.75rem;
  height: 3.75rem;
  filter: drop-shadow(0 0 0.8rem rgba(0, 0, 0, 0.4));
}
@media (width <= 990px) {
  .hero .hero-next::before {
    filter: drop-shadow(0 0 0.9rem rgba(0, 0, 0, 0.2));
  }
}
.hero .hero-next:hover::before {
  transform: translate(-50%, -50%) scale(1.25);
}
.hero .hero-pagination-ui {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 0 1.8rem;
  list-style: none;
  padding: 0;
  margin: 4.375rem 0 0;
}
@media (width <= 990px) {
  .hero .hero-pagination-ui {
    justify-content: center;
  }
}
.hero .hero-pagination-ui .swiper-pagination-bullet {
  position: relative;
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: #ffb000;
  cursor: pointer;
  transform-origin: center;
  transition: transform 0.45s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hero .hero-pagination-ui .swiper-pagination-bullet.swiper-pagination-bullet-active {
  transform: scale(1.8);
  background-color: #414141;
  pointer-events: none;
}

.products {
  position: relative;
  z-index: 5;
}
.products .text {
  width: 75%;
  margin: 4rem auto;
  text-align: center;
}
@media (width <= 1280px) {
  .products .text {
    width: 90%;
  }
}
.products .text h2 {
  font-family: "Poppins Medium", sans-serif;
  margin: 1rem 0;
}
.products .products-list {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
}
@media (width <= 1280px) {
  .products .products-list {
    gap: 0;
  }
}
@media (width <= 990px) {
  .products .products-list {
    justify-content: flex-start;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .products .products-list::-webkit-scrollbar {
    display: none;
  }
}
.products .products-list .product {
  position: relative;
  display: block;
  text-align: center;
  flex: 0 0 auto;
  width: 20%;
}
@media (width <= 1280px) {
  .products .products-list .product {
    padding: 0 1rem;
    width: 25%;
  }
}
@media (width <= 990px) {
  .products .products-list .product {
    flex: 0 0 auto;
    min-width: 19rem;
  }
}
@media (width < 768px) {
  .products .products-list .product {
    padding: 0 0.5rem;
    min-width: 15rem;
  }
}
.products .products-list .product h5 {
  font-family: "Poppins ExtraLight", sans-serif;
  color: #414141;
  font-size: 1.75rem;
}
.products .products-list .product h3 {
  font-family: "Poppins Medium", sans-serif;
  font-size: 2.875rem;
}
.products .products-list .product img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform-origin: center;
  margin-top: 1rem;
}
.products .products-list .product:hover img {
  transform: scale(1.055);
}
@media (width <= 1280px) {
  .products .products-list .product:hover img {
    transform: scale(1);
  }
}

.home .double-column .blob-wrapper {
  display: block;
}
@media (width <= 1280px) {
  .home .double-column .blob-wrapper {
    transform: scale(1.25);
    transform-origin: center;
  }
}
.home .double-column .trace-wrapper {
  display: block;
}
.home .double-column .content .column:first-child {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
@media (width <= 990px) {
  .home .double-column .content .column:first-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.home .double-column .content .column:last-child {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
@media (width <= 990px) {
  .home .double-column .content .column:last-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.home .double-column .flower-lottie {
  display: block;
  position: absolute;
  right: 0;
  width: 25%;
  top: 35%;
}
@media (width <= 990px) {
  .home .double-column .flower-lottie {
    width: 35%;
    top: 55%;
  }
}
.home .double-column .content .column .image img {
  aspect-ratio: 1;
}
.home .double-column .text p {
  margin: 2.25rem 0 3.5rem;
}

.home .triple-column .content .column .image img {
  aspect-ratio: 1;
}

.grecaptcha-badge {
  animation: fadeOut 0.5s forwards;
  pointer-events: none;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.grecaptcha-badge.visible {
  animation: fadeIn 0.5s forwards;
  pointer-events: auto;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightbox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  opacity: 0.85;
  display: none;
  transition: opacity 0.2s;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
}

.lightbox {
  position: fixed;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: 0;
}
.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
}
.lightbox a img {
  border: 0;
}

.lb-outer-container {
  position: relative;
  --zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: #f6f2e5;
}
.lb-outer-container::after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/content/assets/images/lightbox/loading.gif") no-repeat;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.lb-nav .lb-prev,
.lb-nav .lb-next {
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
  cursor: pointer;
  display: block;
  height: 100%;
  outline: 0;
}
.lb-nav .lb-prev:hover,
.lb-nav .lb-next:hover {
  opacity: 1;
}
.lb-nav .lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/content/assets/images/lightbox/prev.png") left 50% no-repeat;
  opacity: 0;
  transition: opacity 0.6s;
}
.lb-nav .lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/content/assets/images/lightbox/next.png") right 50% no-repeat;
  opacity: 0;
  transition: opacity 0.6s;
}

.lb-data-container {
  margin: 0 auto;
  padding-top: 5px;
  --zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.lb-data-container::after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #cfcfcf;
}
.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}
.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}
.lb-data .lb-caption a {
  color: #3392ff;
}
.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #e6e6e6;
}
.lb-data .lb-close {
  display: block;
  float: right;
  width: 20px;
  height: 20px;
  background: url("/content/assets/images/lightbox/close.png") top right no-repeat;
  text-align: right;
  outline: 0;
  opacity: 0.7;
  transition: opacity 0.2s;
  background-position: center;
  background-size: contain;
}
.lb-data .lb-close:hover {
  cursor: pointer;
  opacity: 1;
}

.product-overview {
  position: relative;
  margin-top: 6rem;
  margin-bottom: 4rem;
}
@media (width <= 990px) {
  .product-overview {
    margin: 3rem 0;
  }
}
.product-overview .title {
  text-align: center;
  z-index: 10;
}
@media (width <= 990px) {
  .product-overview .title.main-title {
    display: none;
  }
}
.product-overview .title h1 {
  font-size: 2.25rem;
  margin: 1rem 0;
}
.product-overview .title p {
  font-size: 1.125rem;
}
.product-overview .product-slider {
  margin: 4rem 0;
  z-index: 10;
}
@media (width <= 990px) {
  .product-overview .product-slider {
    margin: 2rem 0;
  }
}
.product-overview .product-slider swiper-slide {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.product-overview .product-slider swiper-slide .product-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  z-index: 10;
}
@media (width <= 990px) {
  .product-overview .product-slider swiper-slide .product-info {
    flex-direction: column;
  }
}
.product-overview .product-slider swiper-slide .product-info .title {
  text-align: left;
  margin-left: -9rem;
  width: 50%;
}
@media (width <= 1280px) {
  .product-overview .product-slider swiper-slide .product-info .title {
    margin-left: -12rem;
  }
}
@media (width <= 990px) {
  .product-overview .product-slider swiper-slide .product-info .title {
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }
}
.product-overview .product-slider swiper-slide .product-info .title h2 {
  font-family: "Poppins ExtraLight", sans-serif;
}
.product-overview .product-slider swiper-slide .product-info .title h2 span {
  font-family: "Poppins SemiBold", sans-serif;
}
.product-overview .product-slider swiper-slide .product-info .title p {
  max-width: 70%;
  margin: 1rem 0 2.5rem;
}
@media (width <= 990px) {
  .product-overview .product-slider swiper-slide .product-info .title p {
    margin: 1rem auto 2.5rem;
  }
}
.product-overview .product-slider swiper-slide .product-info img {
  height: 100%;
  max-height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (width <= 990px) {
  .product-overview .product-slider swiper-slide .product-info img {
    height: auto;
    max-height: none;
    width: 100%;
  }
}
@media (width < 768px) {
  .product-overview .product-slider swiper-slide .product-info img {
    width: 110%;
  }
}
.product-overview .product-slider swiper-slide .left-img,
.product-overview .product-slider swiper-slide .right-img {
  position: absolute;
  top: 0;
  width: auto;
  height: 100%;
  z-index: 2;
}
@media (width <= 1280px) {
  .product-overview .product-slider swiper-slide .left-img,
  .product-overview .product-slider swiper-slide .right-img {
    height: 50%;
  }
}
@media (width <= 990px) {
  .product-overview .product-slider swiper-slide .left-img,
  .product-overview .product-slider swiper-slide .right-img {
    height: 35%;
  }
}
@media (width < 768px) {
  .product-overview .product-slider swiper-slide .left-img,
  .product-overview .product-slider swiper-slide .right-img {
    height: 25%;
  }
}
.product-overview .product-slider swiper-slide .left-img img,
.product-overview .product-slider swiper-slide .right-img img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.product-overview .product-slider swiper-slide .left-img {
  left: 0;
}
.product-overview .product-slider swiper-slide .right-img {
  right: 0;
}
@media (width <= 990px) {
  .product-overview .product-slider swiper-slide .right-img {
    top: 25%;
  }
}
.product-overview .product-slider swiper-slide .right-img img {
  float: right;
}
.product-overview .products-navigation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  z-index: 10;
}
.product-overview .products-navigation .products-thumbs {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}
.product-overview .products-navigation a.active img {
  transform: scale(1.1);
}
.product-overview .products-navigation a img {
  height: 5.625rem;
  transition: transform 0.3s ease-in-out;
}
.product-overview .products-navigation a img:hover {
  cursor: pointer;
  transform: scale(1.1);
}
@media (width <= 990px) {
  .product-overview .products-navigation a img {
    height: auto;
    width: 100%;
  }
}
.product-overview .products-navigation .slider-nav {
  margin: 0 1rem;
  cursor: pointer;
  transition: color, opacity 0.3s ease-in-out;
}
.product-overview .products-navigation .slider-nav:hover {
  color: #ffb000;
}
.product-overview .products-navigation .slider-nav.swiper-button-disabled {
  color: inherit;
  opacity: 0.3;
}
.product-overview .left-blob,
.product-overview .right-blob {
  position: absolute;
  top: 0;
  width: 40%;
  z-index: 1;
}
.product-overview .left-blob path,
.product-overview .right-blob path {
  transition: fill 0.3s ease-in-out;
}
@media (width <= 990px) {
  .product-overview .left-blob,
  .product-overview .right-blob {
    width: 75%;
  }
}
.product-overview .left-blob {
  left: -10%;
}
@media (width <= 990px) {
  .product-overview .left-blob {
    top: -15%;
    left: -30%;
  }
}
.product-overview .right-blob {
  right: -10%;
}
@media (width <= 990px) {
  .product-overview .right-blob {
    top: 15%;
    right: -30%;
  }
}

@media (width < 768px) {
  .prodotti .double-column {
    margin: 5rem 0 7rem;
  }
}
.prodotti .double-column .blob-wrapper {
  display: block;
}
@media (width <= 1280px) {
  .prodotti .double-column .blob-wrapper {
    transform: scale(1.25);
    transform-origin: center;
  }
}
@media (width <= 990px) {
  .prodotti .double-column .blob-wrapper {
    top: auto;
    bottom: -20%;
    left: -18%;
    width: 130%;
  }
}
@media (width < 768px) {
  .prodotti .double-column .blob-wrapper {
    bottom: -30%;
    left: -60%;
    width: 200%;
  }
}
.prodotti .double-column .content .column:first-child {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
@media (width <= 990px) {
  .prodotti .double-column .content .column:first-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (width < 768px) {
  .prodotti .double-column .content .column:first-child {
    padding: 0;
  }
}
.prodotti .double-column .content .column:last-child {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
@media (width <= 990px) {
  .prodotti .double-column .content .column:last-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.prodotti .double-column .content .column .image img {
  aspect-ratio: 1;
}
@media (width <= 990px) {
  .prodotti .double-column .content .column .text {
    margin: 1.5rem auto 0;
  }
}
.prodotti .double-column .content .column .text p {
  margin: 2.25rem 0 3.5rem;
}

html,
body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  color: #414141;
}

html.lenis,
html.lenis body {
  height: 100%;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

#frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0 2rem;
}
@media (width <= 990px) {
  #frame {
    padding: 0 1rem;
  }
}

#wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - var(--header-height));
  border-radius: var(--br-default) var(--br-default) 0 0;
  overflow: auto !important;
}

#swup {
  position: relative;
  border-radius: 0 0 var(--br-default) var(--br-default);
  overflow: hidden;
  background: url("/content/assets/images/background.svg");
  background-color: #f7f6f5;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: none;
}

::-webkit-scrollbar {
  width: 0;
}